import { vehicleSearchPaged, vehicleTypesQuery, userStatusListQuery, storeLocations } from 'graphql-queries'
import makeSelect from '@isoftdata/select'
import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'
import makeSearchInterface from '@isoftdata/search-interface'
import makeAutocomplete from '@isoftdata/autocomplete'
import { sortArrayByObjectKey } from '@isoftdata/utility-array'
import pProps from 'p-props'
import template from './vehicle-search.html'
import utilityStorage from '@isoftdata/utility-storage'
const { getObject, setObject } = utilityStorage

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.vehicle-search',
		route: 'vehicle-search',
		defaultChild: 'results',
		querystringParameters: [ 'searchQuery', 'pageNumber', 'perPageCount', 'orderBy', 'location', 'typeIds', 'category', 'userStatuses', 'statuses', 'dismantled' ],
		data: {
			saving: {},
			searchQuery: {},
			perPageCount: 10,
		},
		template: {
			template,
			components: {
				itSelect: makeSelect({ twoway: true }),
				itInput: makeInput({ twoway: true, lazy: 100 }),
				itButton: makeButton(),
				itAutoComplete: makeAutocomplete(),
				searchInterface: makeSearchInterface({
					oninit() {
						console.log(this.get('newButton'))
					},
				}),
			},
		},
		async resolve(stateRouterData, parameters) {
			const {
				lastSearchQuery,
				lastUserStatuses,
				lastCategory,
				lastTypeIds,
				lastLocation,
				lastOrderBy,
				lastStatuses,
				lastDismantled,
			} = getObject(localStorage, 'LastSearch') || {}
			const searchString = parameters.searchQuery ? decodeURIComponent(parameters.searchQuery) : (lastSearchQuery ? lastSearchQuery : '')
			const storeId = JSON.parse(sessionStorage.getItem('user')).currentStore
			//const available = true
			//const pagination = { "pageSize": 10 }
			//pagination.pageNumber = parameters.pageNumber ? parseInt(decodeURIComponent(parameters.pageNumber), 10) : 1
			const pageNumber = parameters.pageNumber ? parseInt(decodeURIComponent(parameters.pageNumber), 10) : 1
			//const perPageCount = parameters.perPageCount ? Number(decodeURIComponent(parameters.perPageCount)) : 10
			const perPageCount = parseInt(localStorage.getItem('numVehicleRows') ?? parameters.perPageCount, 10)
			//const orderBy = parameters.orderBy ? decodeURIComponent(parameters.orderBy).split(',') : [ "componentCount_ASC", "dateEntered_ASC" ]
			const orderBy = parameters.orderBy ? decodeURIComponent(parameters.orderBy) : (lastOrderBy ? lastOrderBy : "componentCount_ASC,dateEntered_ASC")
			const location = parameters.location ? decodeURIComponent(parameters.location) : (lastLocation ? lastLocation : '')
			const typeIds = parameters.typeIds ? parseInt(decodeURIComponent(parameters.typeIds), 10) : (lastTypeIds ? lastTypeIds : null)
			const category = parameters.category ? decodeURIComponent(parameters.category) : (lastCategory ? lastCategory : '')
			const userStatuses = parameters.userStatuses ? decodeURIComponent(parameters.userStatuses) : (lastUserStatuses ? lastUserStatuses : null)

			const dismantled = parameters.dismantled ? decodeURIComponent(parameters.dismantled) : (lastDismantled ? lastDismantled : 'false')
			// Split status parameter result into an array
			const statuses = parameters.statuses ? decodeURIComponent(parameters.statuses) : (lastStatuses ? lastStatuses : '@')
			const vehiclesFilterBy = { category, location, typeIds, userStatuses, statuses }
			if (vehiclesFilterBy.statuses === '@') {
				vehiclesFilterBy.statuses = [ 'A', 'H' ]
			}
			if (vehiclesFilterBy.statuses === 'ALL') {
				delete vehiclesFilterBy.statuses
			}
			if (dismantled !== 'both') {
				vehiclesFilterBy.dismantled = dismantled == 'true'
			}

			/*	const asOptionObject = array => {
					return	array.locations.reduce((acc, location) => {
						return { ...acc, [location.name]: location.name }
					}, {})
				}*/
			const { userStatusResponse, vehicleTypes, locationResponse, vehicleResponse } = await pProps({
				userStatusResponse: mediator.publish('graphqlFetchWithCache', { query: userStatusListQuery }),
				vehicleTypes: mediator.publish('graphqlFetchWithCache', { query: vehicleTypesQuery, mutator: res => sortArrayByObjectKey({ array: res.vehicleTypes, key: 'name' }) }),
				locationResponse: mediator.publish('graphqlFetchWithCache', { query: storeLocations, variables: { storeId }, minutesToLive: 300 }),
				vehicleResponse: mediator.publish('graphqlFetch', vehicleSearchPaged, {
					stores: storeId,
					searchString,
					pagination: { pageNumber, pageSize: perPageCount },
					orderBy: orderBy.split(','),
					vehiclesFilterBy,
				}),
			})

			const resultsColumns = [
				{ property: 'imageCount', name: 'Images' },
				{ property: 'stockNumber', name: 'Stock Number' },
				{ property: 'year', name: 'Year' },
				{ property: 'make', name: 'Make' },
				{ property: 'model', name: 'Model' },
				{ property: 'vin', name: 'VIN' },
				{ property: 'location', name: 'Location' },
				{ property: 'category', name: 'Category' },
				{ property: 'type', name: 'Type' },
				{ property: 'componentCount', name: 'Parts' },
				{ property: 'status', name: 'Status' },
				{ property: 'userStatus', name: 'User Status' },
				{ property: 'receivedDate', name: 'Received Date' },
				{ property: 'dateEntered', name: 'Date Entered' },
			]

			const [ sortProperty, sortDirection ] = orderBy.split(',')[0].split('_')

			return {
				searchQuery: searchString,
				pageNumber,
				perPageCount,
				orderBy,
				resultsColumns,
				sortColumn: resultsColumns.find(column => column.property === sortProperty),
				sortDirection,
				location,
				typeIds,
				category,
				userStatuses,
				statuses,
				storeId,
				dismantled,
				vehicleTypeIdFilter: '',
				vehicleStatusList: [ // How do I get these w/o manually adding them here?
					{ abbrev: '@', vehicleStatus: '@', description: 'On Hand' },
					{ abbrev: 'A', vehicleStatus: 'A', description: 'Available' },
					{ abbrev: 'H', vehicleStatus: 'H', description: 'On Hold' },
					{ abbrev: 'S', vehicleStatus: 'S', description: 'Sold' },
					{ abbrev: 'C', vehicleStatus: 'C', description: 'Crushed' },
					{ abbrev: 'D', vehicleStatus: 'D', description: 'Deleted' },
					{ abbrev: 'B', vehicleStatus: 'B', description: 'Bidding' },
				],
				vehicles: vehicleResponse.vehicles,
				userStatusList: userStatusResponse.userVehicleStatusList,
				vehicleTypes,
				locations: locationResponse.locations,
				showAdvancedSearchFields: true,
			}
		},
		activate({ domApi: ractive }) {
			console.log(ractive.get())
			const doSearch = function() {
				const value = ractive.get('searchQuery')
				const orderBy = ractive.get('orderBy')
				const location = ractive.get('location')
				const typeIds = ractive.get('typeIds')
				const category = ractive.get('category')
				const userStatuses = ractive.get('userStatuses')
				const statuses = ractive.get('statuses')
				const dismantled = ractive.get('dismantled')
				const perPageCount = ractive.get('perPageCount')
				//const pageSize = ractive.get('perPageCount')
				setObject(localStorage, 'LastSearch', {
					lastSearchQuery: value,
					lastUserStatuses: userStatuses,
					lastCategory: category,
					lastTypeIds: typeIds,
					lastLocation: location,
					lastOrderBy: orderBy,
					lastStatuses: statuses,
					lastDismantled: dismantled,
				})

				if (value.trim().length >= 1) {
					stateRouter.go('app.vehicle-search.results', {
						searchQuery: value,
						pageNumber: 1,
						perPageCount,
						orderBy,
						location,
						typeIds,
						category,
						userStatuses,
						statuses,
						dismantled,
					}, { replace: true, inherit: false })
				}				else {
					stateRouter.go('app.vehicle-search.results', {
						pageNumber: 1,
						perPageCount,
						orderBy,
						location,
						typeIds,
						category,
						userStatuses,
						statuses,
						dismantled,
					}, { replace: true })
				}
			}
			ractive.on('search', doSearch)

			ractive.on('create-new-vehicle-clicked', function() {
				let storeId = ractive.get('storeId')
				stateRouter.go('app.vehicle', { storeId })
			})
			ractive.on('clearSearch', function() {
				ractive.set({
					searchQuery: '',
					orderBy: "componentCount_ASC,dateEntered_ASC",
					location: '',
					typeIds: null,
					category: '',
					userStatuses: null,
					statuses: '@',
					dismantled: 'false',
				})
				doSearch()
			})
		},
	})
}
