import cache from 'memory-cache'
import { reduceToTruthyValues } from '@isoftdata/utility-object'

// Having the whole query in the error message makes it impossible to see the acutal error.
const TRIM_REGEX = new RegExp('(.*got invalid value) {.*};(.*)')

const getNonNullishProps = obj => {
	const newObj = {}
	for (const key in obj) {
		if (!(obj[key] === undefined || obj[key] === null)) {
			newObj[key] = obj[key]
		}
	}

	return newObj
}

const apiFetch = async(apiUrl, { method = 'POST', headers, body }) => {
	headers = {
		'auth-token': localStorage.getItem('authToken'),
		'apollographql-client-name': 'EnterpriseWeb',
		'apollographql-client-version': '',
		...headers || {},
	}
	headers = getNonNullishProps(headers)

	const res = await fetch(apiUrl, {
		method,
		headers,
		body,
	})

	const jsonRes = await res.json()
	return jsonRes
}

const mutateAndCache = ({ cacheKey, res, minutesToLive, mutator }) => {
	const millisecs = minutesToLive * 60 * 1000
	const mutatedValue = mutator(res)
	cache.put(cacheKey, mutatedValue, millisecs)

	return mutatedValue
}

const apiCall = async(apiUrl, query, variables) => {
	const debugEnabled = false

	if (debugEnabled) {
		console.groupCollapsed(query.split('{', 1)[0])

		if (variables) {
			console.log('variables', variables)
			console.log(JSON.stringify(variables, null, 2))
		}

		console.log(query)
		console.groupEnd()
	}

	const { errors, data } = await apiFetch(apiUrl, {
		headers: {
			'Content-Type': 'application/json',
		}, body: JSON.stringify({ query, variables }),
	})

	if (debugEnabled && data) {
		console.log('data', data)
	}

	/*	if (errors) {
		console.error(`error in query ${query.split('{', 1)[0]}`, errors)
		throw errors
	} else {
		return data
	}*/
	// Sometimes the API will "return" data that is useless when we get an error, so only count it if it returns something non nullish
	const hasData = !!data && Object.keys(reduceToTruthyValues({ object: data, options: { objectRecursive: true, onlyNoNullish: true } })).length > 0

	const trimmedErrors = errors?.map(({ message, ...err }) => {
		const matches = message.match(TRIM_REGEX)
		if (matches) {
			message = `${matches[1]}.${matches[2]}`
		}
		return { ...err, message }
	})

	if (errors && hasData) {
		console.error('data returned with error')
		console.error('data', data)

		console.error('errors', trimmedErrors)
	}

	if (hasData || !errors) {
		return data
	}

	console.error(`error in query ${query.split('{', 1)[0]}`, trimmedErrors.map(({ message }) => message).join('\n'))
	throw trimmedErrors
}

function getUploadFileAttachmentQuery(relation) {
	if (relation == 'vehicle') {
		return `
		#graphql
		mutation($file: Upload!, $rank: Int, $relationId: UInt!) {
			createVehicleAttachment(input: { vehicleId: $relationId, rank: $rank, file: $file }) {
			  fileId: id
			  rank
			  public
			  file {
				  id
				  name
				  path: url
				  type
				  size
				  mimeType: mimetype
				  extension
				  updated
				  createdDate: created
			  }
			}
		  }`
	}
	if (relation == 'inventory') {
		return `
		#graphql
		mutation($file: Upload!, $rank: Int, $relationId: UInt!) {
			createItemAttachment(input: { id: $relationId, rank: $rank, file: $file }) {
			  fileId: id
			  rank
			  public
			  file {
				  id
				  name
				  path: url
				  type
				  size
				  mimeType: mimetype
				  extension
				  updated
				  createdDate: created
			  }
			}
		  }`
	}
}

/**
 * Image upload function
 * @param {string} apiUrl
 * @param {*} param1
 * @returns {Promise}
 */
const uploadFileAttachment = (apiUrl, { file, relationId, relation, rank }) => {
	const formData = new FormData()
	const variables = { file: null, rank, relationId }

	const query = getUploadFileAttachmentQuery(relation)

	formData.append('operations', JSON.stringify({ query, variables }))
	formData.append('map', '{ "0": ["variables.file"] }')
	formData.append('0', file)

	return apiFetch(apiUrl, { body: formData })
}

/**
 *
 * @param {*} apiUrl
 * @param {*} relationId
 * @param {*} files
 * @returns Object - { data: { createItemAttachments: [] } }
 */
function uploadFilesAttachments(apiUrl, relationId, files = []) {
	if (!files.length) {
		return Promise.resolve({ data: { createItemAttachments: [] } })
	}
	const formData = new FormData()
	// just inventory items right now
	const query = `#graphql
		mutation LIB_CreateItemAttachments($input: NewItemAttachments!) {
		createItemAttachments(input: $input) {
			fileId: id
			rank
			public
			file {
				id
				name
				path: url
				type
				size
				mimeType: mimetype
				extension
				updated
				createdDate: created
			}
		}
	}`

	const { variables, map } = files.reduce(({ variables, map }, { public: isPublic, rank }, index) => {
		map[index] = [ `variables.input.attachments.${index}.file` ]
		variables.input.attachments[index] = { file: null, public: isPublic, rank }
		return { variables, map }
	}, { variables: { input: { sku: relationId, attachments: [] } }, map: {} })

	const operations = {
		query,
		variables,
	}

	formData.append('operations', JSON.stringify(operations))
	formData.append('map', JSON.stringify(map))
	files.forEach(({ file }, index) => {
		formData.append(index, file)
	})

	return apiFetch(apiUrl, { body: formData })
}

export default function({ apiURL, mediator }) {
	mediator.provide('graphqlFetch', (query, variables) => {
		return apiCall(apiURL, query, variables)
	})

	// Made a new provider because I didn't want to add an argument after the callback to the above provider, or replace all callbacks with promises right now
	mediator.provide('graphqlFetchPath', async(query, variables, keypath) => {
		const res = await apiCall(apiURL, query, variables)
		if (keypath) {
			let value = res
			for (const key of keypath.split('.')) {
				value = value?.[key]
			}
			return value
		} else {
			return res
		}
	})

	mediator.provide('graphqlFetchWithCache', ({ query, variables = null, minutesToLive = 60, mutator = v => v } = {}) => {
		const cacheKey = JSON.stringify({ query, variables })
		const cachedData = cache.get(cacheKey)

		if (cachedData) {
			return Promise.resolve(cachedData)
		} else {
			return new Promise((resolve, reject) => {
				apiCall(apiURL, query, variables)
					.then(res => {
						resolve(mutateAndCache({ cacheKey, res, minutesToLive, mutator }))
					})
					.catch(reject)
			})
		}
	})

	mediator.provide('uploadFileAttachment', async({ file, relationId, relation, rank }) => {
		const res = await uploadFileAttachment(apiURL, { file, relationId, relation, rank })
		const { errors } = res
		if (errors && Array.isArray(errors)) {
			throw errors[0]
		}
		return res
	})

	mediator.provide('uploadFileAttachments', async(relationId, files = []) => {
		const res = await uploadFilesAttachments(apiURL, relationId, files)
		const { errors } = res
		if (errors && Array.isArray(errors)) {
			throw errors[0]
		}
		return res
	})
}
