import { sessionInformation } from 'graphql-queries'
import template from './user.html'

export default function(appContext) {
	var mediator = appContext.mediator

	appContext.stateRouter.addState({
		name: 'app.configuration.user',
		route: 'user',

		template: {
			template,
		},
		data: {

		},
		async resolve(data, parameters) {
			return {
				sessionData: await mediator.publish('graphqlFetch', sessionInformation),
			}
		},
		activate(context) {
			var ractive = context.domApi
			ractive.observe('sessionData', values => {
				console.log(values)
			}, { init: true })
		},
	})
}
