import template from './not-found.html'

export default function(appContext) {
	const socket = appContext.socket
	const mediator = appContext.mediator

	appContext.stateRouter.addState({
		name: 'not-found',
		route: 'not-found',
		querystringParameters: [ 'route', 'parameters' ],
		template: {
			template,
		},
		activate(context) {
			var ractive = context.domApi

			ractive.set('route', context.parameters.route)
			ractive.set('parameters', context.parameters.parameters)
		},
	})
}
