export default function(appContext) {
	appContext.stateRouter.addState({
		name: 'app.dummy',
		route: 'dummy',
		querystringParameters: [ 'state' ],
		template: {
			template: '',
			data: {},
		},
		resolve(data, parameters) {
			let stateParams = Object.create(parameters)
			delete stateParams.state

			return Promise.all({ stateParams })
		},
		activate(context) {
			if (context.parameters.state) {
				appContext.stateRouter.go(context.parameters.state,
					context.domApi.get('stateParams'),
					{ replace: true })
			}
		},
	})
}
