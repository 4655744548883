import template from './error.html'

export default function(appContext) {
	const socket = appContext.socket
	const mediator = appContext.mediator

	appContext.stateRouter.addState({
		name: 'error',
		route: 'error',
		querystringParameters: [ 'err' ],
		template: {
			template,
		},
		activate(context) {
			var ractive = context.domApi

			ractive.set('err', context.parameters.err)
		},
	})
}
