import { settingValuesQuery } from 'graphql-queries'
import template from './device.html'
import { getObject, setObject } from '@isoftdata/utility-storage'
//const stringToCodesArray = string => string.split('').map(a => a.charCodeAt(0))
//const convertCodesToString = codeList => codeList.map(code => String.fromCharCode(code)).join('')

export default function(appContext) {
	const mediator = appContext.mediator

	appContext.stateRouter.addState({
		name: 'app.configuration.device',
		route: 'device',

		template: {
			template,
			computed: {

			},
			stringToCodesArray: string => string.split('').map(a => a.charCodeAt(0)),
			convertCodesToString: codeList => codeList.map(code => String.fromCharCode(code)).join(''),

		},
		data: {

		},
		// eslint-disable-next-line require-await
		async resolve(data, parameters) {
			const scannerOptions = getObject(localStorage, 'scannerOptions') || {
				preamble: '~',
				postamble: [ 13 ],
			}

			return {
				scannerOptions,
				preambleAsArray: Array.isArray(scannerOptions.preamble),
				postambleAsArray: Array.isArray(scannerOptions.postamble),
			}
		},
		activate(context) {
			var ractive = context.domApi

			ractive.observe('scannerOptions', options => {
				console.log(options)
				setObject(localStorage, 'scannerOptions', options)
			}, { init: false })
		},
	})
}
