import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'
import makeSelect from '@isoftdata/select'
import makeItSelectWithFilter from '@isoftdata/select-with-modal'
import makeSearchInterface from '@isoftdata/search-interface'
import makeNumberInputRangeComponent from 'components/number-input-range-component'
import makeAutocomplete from '@isoftdata/autocomplete'
import { storeQuery, inventorySearchQuery, inventoryTypesQuery } from 'graphql-queries'
import { sortArrayByObjectKey } from '@isoftdata/utility-array'
import pProps from 'p-props'
import template from './part-search.html'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.part-search',
		route: 'part-search',
		defaultChild: 'results',
		querystringParameters: [
			'pageNumber',
			'orderBy',
			'storeId',
			'perPageCount',
			'inventoryStatus',
			'keyword',
			'inventoryTypeId',
			'inStockOnly',
			'quantityFrom',
			'quantityTo',
			'quantityOnHoldFrom',
			'quantityOnHoldTo',
			'quantityAvailableFrom',
			'quantityAvailableTo',
			'quantityOnOrderFrom',
			'quantityOnOrderTo',
		],
		data: {
			perPageCount: 10, // Is this on first load, do I even need this?
			//tagNumber: '',
			store: null,
			pageNumber: 1,
			orderBy: null,
		},
		template: {
			template,
			components: {
				itInput: makeInput({ lazy: 300, twoway: true }),
				itButton: makeButton(),
				itSelect: makeSelect({ twoway: true }),
				itAutocomplete: makeAutocomplete({ twoway: true }),
				searchInterface: makeSearchInterface(),
				itSelectWithFilter: makeItSelectWithFilter({ twoway: true }),
				numberRangeInput: makeNumberInputRangeComponent(),
			},
		},
		async resolve(stateRouterData, parameters) {
			const pageNumber = parameters.pageNumber ? Number(parameters.pageNumber) : 1
			const perPageCount = parseInt(localStorage.getItem('numPartRows') ?? parameters.perPageCount, 10)

			const orderBy = parameters.orderBy || null
			const storeId = parameters.storeId || JSON.parse(sessionStorage.getItem('user')).currentStore
			const inventoryStatus = parameters.inventoryStatus || '@'
			const keyword = parameters.keyword || ''
			const inventoryTypeId = parameters.inventoryTypeId || null

			const buildValueComparisonObject = (from, to) => {
				return (from || to) ? {
					gte: parseInt(from, 10) || undefined,
					lte: parseInt(to, 10) || undefined,
				} : undefined
			}

			const { data, results, inventoryTypeList } = await pProps({
				data: mediator.publish('graphqlFetchWithCache', { query: storeQuery, minutesToLive: 600 }),
				results: mediator.publish('graphqlFetch', inventorySearchQuery, {
					pagination: { pageNumber, pageSize: perPageCount },
					filter: {
						stores: storeId === 'all' ? null : [ Number(storeId) ],
						statuses: inventoryStatus === '@' ? [ 'A', 'H' ] : [ inventoryStatus ],
						keyword,
						inventoryTypeId: inventoryTypeId ? { value: parseInt(inventoryTypeId, 10) } : undefined,
						quantity: buildValueComparisonObject(parameters.quantityFrom, parameters.quantityTo),
						quantityOnHold: buildValueComparisonObject(parameters.quantityOnHoldFrom, parameters.quantityOnHoldTo),
						quantityOnOrder: buildValueComparisonObject(parameters.quantityOnOrderFrom, parameters.quantityOnOrderTo),
						quantityAvailable: buildValueComparisonObject(parameters.quantityAvailableFrom, parameters.quantityAvailableTo),
					},
					orderBy,
				}),
				//TODO this should order by name via an API arg(which doesn't currenlty exist)
				inventoryTypeList: mediator.publish('graphqlFetchWithCache', { query: inventoryTypesQuery, variables: { active: true }, minutesToLive: 300, mutator: res => {
					let inventoryTypeList = sortArrayByObjectKey({ array: res.inventoryTypeList, key: 'name' })

					inventoryTypeList = inventoryTypeList.map(obj => {
						obj.partManufacturers = sortArrayByObjectKey({ array: obj.partManufacturers, key: 'name' })
						return obj
					})
					return inventoryTypeList
				} }),
			})

			const resultsColumns = [ // Some column 'properties' match the API orderBy enum instead of client
				{ property: 'storeId', name: 'Store' },
				{ property: 'attachmentCount', icon: 'fas fa-fw fa-photo-video' },
				{ property: 'tagNumber', name: 'Tag #' },
				{ property: 'inventoryTypeName', name: 'Part Type' },
				{ property: 'manufacturerName', name: 'Part Manufacturer' },
				{ property: 'modelName', name: 'Part Model' },
				{ property: 'category', name: 'Category' },
				{ property: 'vehicleYear', name: 'Year' },
				{ property: 'vehicleMake', name: 'Make' },
				{ property: 'vehicleModel', name: 'Model' },
				{ property: 'bodyStyle', name: 'Body Style' },
				{ property: 'side', name: 'Side' },
				{ property: 'oemNumber', name: 'OEM #' },
				{ property: 'dateEntered', name: 'Date Created' },
				{ property: 'status', name: 'Status' },
				{ property: 'userStatus', name: 'User Status' },
				{ property: 'quantity', name: 'Qty' },
				{ property: 'wholesalePrice', name: 'Wholesale' },
				{ property: 'retailCorePrice', name: 'Core' },
				{ property: 'retailPrice', name: 'Retail' },
				{ property: 'typeField1', name: 'Type Field 1' },
				{ property: 'typeField2', name: 'Type Field 2' },
				{ property: 'typeField3', name: 'Type Field 3' },
				{ property: 'typeField4', name: 'Type Field 4' },
			]

			let sortDirection = null, sortColumn = null

			if (orderBy) {
				let sortProperty
				[ sortProperty, sortDirection ] = orderBy.split('_')
				sortColumn = resultsColumns.find(column => column.property === sortProperty)
			}

			return {
				...results,
				stores: data.stores,
				pageNumber: results.inventories.pageInfo.pageNumber,
				//tagNumber,
				keyword,
				perPageCount,
				storeId,
				orderBy,
				sortDirection,
				sortColumn,
				resultsColumns,
				inventoryStatus,
				inventoryStatusList: [
					{ abbrev: '@', description: 'Status A or H' },
					{ abbrev: 'A', description: 'Available' },
					{ abbrev: 'H', description: 'On Hold' },
					{ abbrev: 'S', description: 'Sold' },
					{ abbrev: 'C', description: 'Crushed' },
					{ abbrev: 'D', description: 'Deleted' },
					{ abbrev: 'T', description: 'Transferred' },
					{ abbrev: 'B', description: 'Bidding' },
				],
				inventoryTypeList,
				inventoryTypeId,
				showAdvancedSearchFields: true,
				inStockOnly: parameters.quantityFrom || parameters.quantityTo,
				quantityFrom: parameters.quantityFrom || null,
				quantityTo: parameters.quantityTo || null,
				quantityOnHoldFrom: parameters.quantityOnHoldFrom || null,
				quantityOnHoldTo: parameters.quantityOnHoldTo || null,
				quantityAvailableFrom: parameters.quantityAvailableFrom || null,
				quantityAvailableTo: parameters.quantityAvailableTo || null,
				quantityOnOrderFrom: parameters.quantityOnOrderFrom || null,
				quantityOnOrderTo: parameters.quantityOnOrderTo || null,
			}
		},
		activate({ domApi: ractive }) {
			ractive.on('search', (context, event) => {
				event?.preventDefault()

				stateRouter.go('app.part-search.results', {
					keyword: ractive.get('keyword'),
					pageNumber: 1, //ractive.get('pageNumber')
					perPageCount: ractive.get('perPageCount'),
					orderBy: ractive.get('orderBy'),
					storeId: ractive.get('storeId'),
					inventoryStatus: ractive.get('inventoryStatus'),
					inventoryTypeId: ractive.get('inventoryTypeId'),
					quantityFrom: ractive.get('quantityFrom'),
					quantityTo: ractive.get('quantityTo'),
					quantityOnHoldFrom: ractive.get('quantityOnHoldFrom'),
					quantityOnHoldTo: ractive.get('quantityOnHoldTo'),
					quantityAvailableFrom: ractive.get('quantityAvailableFrom'),
					quantityAvailableTo: ractive.get('quantityAvailableTo'),
					quantityOnOrderFrom: ractive.get('quantityOnOrderFrom'),
					quantityOnOrderTo: ractive.get('quantityOnOrderTo'),
				}, { replace: true })
			})

			ractive.on('clearSearch', function() {
				stateRouter.go('app.part-search')
			})

			ractive.on('inStockOnlyChanged', ({ inStockOnly }) => {
				ractive.set({
					quantityFrom: inStockOnly ? 1 : null,
					quantityTo: null,
				})
			})

			ractive.observe('quantityFrom', quantityFrom => {
				ractive.set('inStockOnly', quantityFrom > 0)
			})
		},
	})
}
