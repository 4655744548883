import makeItTable from '@isoftdata/table'
import makeImageThumbnailViewer from '@isoftdata/image-thumbnail-viewer'
import makeItImageViewerModal from '@isoftdata/image-viewer-modal'
import makeItCheckbox from '@isoftdata/checkbox'
import makeItButton from '@isoftdata/button'
import { stringToBoolean } from '@isoftdata/utility-string'
import template from './results.html'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.vehicle-search.results',
		route: 'results',
		querystringParameters: [ 'selectedVehicleId', 'pageNumber', 'pageSize', 'perPageCount' ],
		defaultParameters: {
			pageNumber: 1,
			perPageCount: 10,
		},
		data: {},
		template: {
			template,
			components: {
				itTable: makeItTable({
					methods: {
						columnClicked(column, direction) {
							const orderBy = (column.orderBy ?? column.property).concat('_', direction)
							stateRouter.go('app.vehicle-search.results', { orderBy, pageNumber: 1 }, { inherit: true })
						},
					},
					stickyHeader: true,
				}),
				imageThumbnailViewer: makeImageThumbnailViewer(),
				imageViewerModal: makeItImageViewerModal(),
				itButton: makeItButton(),
				itCheckbox: makeItCheckbox(),
			},
			computed: {
				pages() {
					const pagesCount = this.get('vehicles.pageInfo.totalPages')
					var pages = []
					var n = 1

					while (n <= pagesCount) {
						pages.push({ number: n })
						n++
					}

					return pages
				},
				displayPages() {
					const pages = this.get('pages')
					const currentPageNumber = this.get('vehicles.pageInfo.pageNumber')
					const lastPage = pages.length
					return pages.reduce((sum, page) => {
						if (lastPage == 7
						|| currentPageNumber == page.number || page.number == 1 || page.number == lastPage ||
						(page.number > currentPageNumber - 3 && page.number < currentPageNumber + 3)
						||	(page.number <= 5 && currentPageNumber <= 5)
						|| (page.number > lastPage - 5 && currentPageNumber > lastPage - 5)
						//|| (page.number%5 ==0)
						) {
							return sum.concat(page)
						}
						return sum
					}, [])
				},
			},
		},
		resolve(data, parameters) {
		//	console.log("Data", data, "Parameters", parameters)
			return Promise.resolve({
				selectedVehicleId: parameters.selectedVehicleId,
				imageThumbSize: 64,
				hideHeaders: localStorage.getItem('hideHeaders') || 'true',
				showThumbnails: stringToBoolean(localStorage.getItem('showThumbs') ?? true),
				pageNumber: Number(parameters.pageNumber),
				perPageCount: parseInt(localStorage.getItem('numVehicleRows') ?? parameters.perPageCount, 10),
				...data,
			})
		},
		activate({ domApi: ractive }) {
			const vehicles = ractive.get('vehicles.items')
			const selectedVehicleId = parseInt(ractive.get('selectedVehicleId'), 10)

			ractive.observe('sortColumn sortDirection', (newVal, oldVal, keypath) => console.log(keypath, newVal))

			if (vehicles.length > 0 && !vehicles.find(vehicle => {
				return vehicle.id === selectedVehicleId
			})) {
				stateRouter.go('app.vehicle-search.results', { selectedVehicleId: vehicles[0].id }, { inherit: true })
			}
			ractive.on('edit-vehicle-clicked', ({ vehicleId }) => {
				let storeId = vehicles.find(vehicle => {
					return vehicle.id === vehicleId
				}).storeId
				//	if(appLayout !== 'teardown')
				stateRouter.go('app.vehicle', { vehicleId, storeId })
				//	else
				//		appContext.stateRouter.go('app.vehicle.teardown', { vehicleId, storeId })
			})
			ractive.on('itTable.selection-change', function(id) {
				stateRouter.go('app.vehicle-search.results', { selectedVehicleId: id }, { inherit: true })
			})

			ractive.on('showThumbnailsClicked', (context, show) => {
				localStorage.setItem('showThumbs', show)
			})

			ractive.on('toggleHeaders', function() {
				const hideHeaders = ractive.get('hideHeaders') == 'true' ? 'false' : 'true'
				localStorage.setItem('hideHeaders', hideHeaders)
				ractive.set('hideHeaders', hideHeaders)
			})
			// Show image viewer modal
			ractive.on('showImages', (context, attachments)=> {
				context.event.stopPropagation()
				ractive.set({ imageViewerShown: true, imageViewerImages: [], currentImageViewerImageIndex: 0 })
				const imageViewerImages = attachments
					.filter(attachment => attachment.file.mimetype.startsWith('image/'))
					.map(attachment => attachment.file.path)
				ractive.set({ imageViewerImages })
			})
			ractive.on('numVehicleRowsChanged', (context, data) => {
				// Save to local storage
				localStorage.setItem('numVehicleRows', data.perPageCount)
				// Resubmit search
				stateRouter.go('app.vehicle-search.results', { pageNumber: 1, perPageCount: data.perPageCount }, { inherit: true })
			})
			ractive.on('itTable.paginationPageChange', ({ pageNumber }) => {
				stateRouter.go('app.vehicle-search.results', { pageNumber }, { inherit: true })
			})
		},
	})
}
