import makeItModalComponent from '@isoftdata/modal'
import { getDistinctArrayValues } from '@isoftdata/utility-array'
import makeItInput from '@isoftdata/input'
import makeItButton from '@isoftdata/button'
import makeItList from '@isoftdata/list'
import makeCheckbox from '@isoftdata/checkbox'
import makeItTextArea from '@isoftdata/textarea'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeItSelect from '@isoftdata/select'
import makeItStatusSelect from 'components/itControl/itStatusSelect'
import makeItAutocomplete from '@isoftdata/autocomplete'
import makeItSelectPlus from '@isoftdata/select-with-modal'

import { sortArrayByObjectKey } from '@isoftdata/utility-array'
import { updateVehicleMutation, createVehicleMutation, vehiclequery, vehicleModelsByMake } from 'graphql-queries'
import { klona } from 'klona'
import statuses from 'common/VehicleStatuses'
import template from './basic.html'

async function loadModelsForMake(make, mediator) {
	const res = await mediator.publish('graphqlFetch', vehicleModelsByMake, { make })
	const models = sortArrayByObjectKey({ array: res.vehicleModels, key: 'name' })

	return (models && Array.isArray(models)) ? models : []
}

export default function(appContext) {
	const mediator = appContext.mediator
	const controlOptions = { twoway: true, lazy: 500 }
	const stateName = 'app.vehicle.basic'

	appContext.stateRouter.addState({
		name: 'app.vehicle.basic',
		route: 'basic',
		querystringParameters: [ 'vehicleId', 'storeId' ],
		defaultParameters: { storeId: 1 },
		template: {
			template,
			components: {
				vinModal: makeItModalComponent(),
				vinNotesModal: makeItModalComponent(),
				itInput: makeItInput(controlOptions),
				itButton: makeItButton(),
				itCheckbox: makeCheckbox(controlOptions),
				itCurrencyInput: makeCurrencyInput(controlOptions),
				itSelect: makeItSelect(controlOptions),
				itStatusSelect: makeItStatusSelect(mediator, controlOptions),
				itSelectPlus: makeItSelectPlus(controlOptions),
				itTextArea: makeItTextArea(controlOptions),
				itList: makeItList(),
				itAutoComplete: makeItAutocomplete(),
			},
			computed: {
				displayVinNotes() {
					return this.get('vehicle.vinNotes')
						.split('\n')
						.map(label => ({ label }))
				},
				editBasic() {
					return this.get('vehiclePermissions.VEHICLES_CAN_EDIT_BASIC')
				},
				vehicleChanged() {
					//console.log(JSON.stringify(this.get('vehicle')))
					return JSON.stringify(this.get('vehicle')) != JSON.stringify(this.get('origVehicle'))
				},
				requiredFieldsComplete() {
					const vehicle = this.get('vehicle')
					const origVehicle = this.get('origVehicle') || {}
					const settings = this.get('settingValues')
					const vehicleId = this.get('vehicleId')
					/*	if (!vehicle.make || !vehicle.model || !vehicle.year || !vehicle.category) {
						return false
					}
*/

					if ((!vehicleId || vehicle.category !== origVehicle.category) && !vehicle.category) {
						return false
					}
					if ((!vehicleId || vehicle.make !== origVehicle.make) && !vehicle.make) {
						return false
					}
					if ((!vehicleId || vehicle.model !== origVehicle.model) && !vehicle.model) {
						return false
					}
					if ((!vehicleId || vehicle.year !== origVehicle.year) && !vehicle.year) {
						return false
					}

					if (settings.vehicle.mileageRequired && (!vehicleId || vehicle.mileage !== origVehicle.mileage) && !vehicle.mileage) {
						return false
					}
					if (settings.vehicle.minVinLength && (!vehicleId || vehicle.vin !== origVehicle.vin) && vehicle.vin.length < settings.vehicle.minVinLength) {
						return false
					}

					return true
				},
			},
			async createVehicle() {
				let vehicle = this.get('vehicle')
				let storeId = this.get('storeId')
				let inventoryTypeId = this.get('vehicleTypeId')

				let variables = {}
				variables.VehicleData = {
					inventoryTypeId,
					storeId,
					category: vehicle.category,
					make: vehicle.make,
					model: vehicle.model,
					year: parseInt(vehicle.year, 10),
					userStatus: "",
					vin: vehicle.vin,
					mileage: vehicle.mileage,
					location: vehicle.location,
				}
				if (vehicle.stockNumber) {
					variables.VehicleData.stockNumber = vehicle.stockNumber
				}
				const res = await mediator.publish('graphqlFetch', createVehicleMutation, variables)
				if (res?.vehicle?.id) {
					appContext.stateRouter.go(null, {
						vehicleId: res.vehicle.id,
						storeId,
					}, { replace: true })
				}
			},
			async saveVehicle() {
				const ractive = this
				let vehicle = ractive.get('vehicle')

				let variables = {}
				variables.VehicleUpdateData = {
					id: vehicle.id,
					notes: vehicle.notes,
					description: vehicle.description,
					category: vehicle.category,
					vin: vehicle.vin,
					dismantled: vehicle.dismantled,
					stockNumber: vehicle.stockNumber,
					year: parseInt(vehicle.year, 10),
					mileage: vehicle.mileage,
					make: vehicle.make,
					model: vehicle.model,
					location: vehicle.location,
					bodyStyle: vehicle.bodyStyle,
					externalColor: vehicle.externalColor,
					externalColorCode: vehicle.externalColorCode,
					internalColor: vehicle.internalColor,
					internalColorCode: vehicle.internalColorCode,
					status: vehicle.status,
					userStatus: vehicle.userStatus,
					titleNumber: vehicle.titleNumber,
					titleStatus: vehicle.titleStatus,
					titleState: vehicle.titleState,
					titleType: vehicle.titleType,
					claimNumber: vehicle.claimNumber,
					purchasedFrom: vehicle.purchasedFrom,
					sellerContact: vehicle.sellerContact,
					sellerPhone: vehicle.sellerPhone,
					sellerInfo: vehicle.sellerInfo,
				}
				await mediator.publish('graphqlFetch', updateVehicleMutation, variables)
				ractive.set('origVehicle', klona(vehicle))
				mediator.publish('vehicleSave', {
					stateThatSaved: stateName,
					data: vehicle,
				})
			},
			selectLocalMakeModelMatch(vinDecode) {
				const ractive = this

				return new Promise((resolve, reject) => {
					const vinNotes = Object.keys(vinDecode.rawData)
						.map(key => `${key }: ${ vinDecode.rawData[key]}`)
						.join('\n')

					ractive.set({
						'vehicle.make': vinDecode.make,
						'vehicle.model': vinDecode.model,
						'vehicle.year': vinDecode.year,
						'vehicle.vinNotes': vinNotes,
					}).then(() => resolve())
				})
			},
		},
		data: {},
		async resolve(data, parameters) {
			const vehicleId = parseInt(parameters.vehicleId, 10)
			const vehicle = parameters.vehicleId ? (await mediator.publish('graphqlFetch', vehiclequery, { vehicleId }))?.vehicle : {}
			const models = vehicle?.make ? await loadModelsForMake(vehicle.make, mediator) : []

			console.log(vehicle)

			//TODO load categories for the inventory type?
			/*
				const catResults = await mediator.publish('graphqlFetch', `#graphql
					query Query($inventoryTypeId: Int!) {
					inventoryType(id: $inventoryTypeId) {
					categories {
						description
						name
						id
					}
					}
				}`, { inventoryTypeId: 9000 })

				res.categories = catResults.inventoryType.categories
			*/

			return {
				vehicle,
				origVehicle: klona(vehicle),
				models,
				categories: vehicle?.wholeUnit?.inventoryType?.categories || [],
				vehicleTypeId: vehicle?.wholeUnit?.inventoryType?.inventoryTypeId || 9000,
				statuses,
				vehicleId: parameters.vehicleId ? parseInt(parameters.vehicleId, 10) : undefined,
				storeId: parameters.storeId ? parseInt(parameters.storeId, 10) : undefined,
				validVIN: true,
				showNotesModal: false,
				showVinModal: false,
				decodedVinObject: {},
				decodedVinNotes: null,
				vinLoading: false,
				modelsLoading: false,
				showVinNotesModal: false,
				possibleMakeModelMatches: [],
				saved: parameters.saved ? JSON.parse(parameters.saved) : [],
				vinMakeModelMatchSession: false,
			}
		},
		activate(context) {
			//context.on('destroy',() => console.log('destroy'))
			window.scrollTo(0, 0)
			const ractive = context.domApi
			//console.log(ractive.get('vehicle'))
			if (!context.parameters.vehicleId) {
				ractive.find('#vin').focus()
			}

			ractive.set('imageSize', ractive.find('#vehicleinfo').getBoundingClientRect().height)

			ractive.observe('vehicleTypeId', vehicleTypeId => {
				const categories = ractive.get("vehicleTypes").find(vehicleType => vehicleType.id === vehicleTypeId).categories

				//const currentCategory = ractive.get("vehicle.category")

				ractive.set({
					categories,

				})
			}, { init: true })

			ractive.observe('vehicle.make', async make => {
				if (make) {
					await ractive.set({ modelsLoading: true })
					try {
						const models = await loadModelsForMake(make, mediator)
						ractive.set({ models, modelsLoading: false })
					} catch (err) {
						console.error(err)
						mediator.publish('showMessage', { heading: 'Error fetching Models', message: err.message, type: 'error', time: 10000 })
					}
				} else {
					ractive.set({ models: [] })
				}
			}, { init: false })

			ractive.on('addModel', ({ make, model, year }) => {
				mediator.publish('emitToServer', 'add make model', { make, model }, function(err) {
					if (err) {
						console.log(err)
					} else {
						const makeList = getDistinctArrayValues(ractive.get('makes').concat(make))
						const modelList = getDistinctArrayValues(ractive.get('models').concat(model))

						ractive.set({
							'vehicle.make': make,
							'vehicle.model': model,
							'vehicle.year': year,
							'makes': makeList,
							'models': modelList,
						})
					}
				})
			})

			ractive.on('useExistingMakeModel', ({ make, model }) => {
				ractive.set({
					'vehicle.make': make,
					'vehicle.model': model,
					'vinMakeModelMatchSession': false,
				})
			})
		},
	})
}

