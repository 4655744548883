import { settingValuesQuery, storeQuery } from 'graphql-queries'
import makeItSelect from '@isoftdata/select'
import makeItInput from '@isoftdata/input'
import makeItButton from '@isoftdata/button'
import { klona } from 'klona'
import template from './store.html'

export default function(appContext) {
	var mediator = appContext.mediator

	appContext.stateRouter.addState({
		name: 'app.configuration.store',
		route: 'store',

		template: {
			template,
			components: {
				itSelect: makeItSelect({ twoway: true }),
				itInput: makeItInput({ twoway: true, lazy: false }),
				itButton: makeItButton(),
			},
			computed: {

				storesChanged() {
					return JSON.stringify(this.get('stores')) != JSON.stringify(this.get('originalStores'))
				},
			},
			async saveStore() {
				const ractive = this

				const changedStore = ractive.get('currentStore')

				const updateStoreQuery = `	mutation UpdateStore($input: StoreUpdate!) {
					updateStore(input: $input) {
					  id
					  addressService {
						apiKey
						service
					  }
					  name
					  taxService {
						apiKey
						service
					  }
					  code
					  phone
					}
				  }
				  `
				/*	  "input": {
					"id": null,
					"name": null,
					"addressService": {
					  "apiKey": null,
					  "service": null
					},
					"taxService": {
					  "apiKey": null,
					  "service": null
					}
				  }*/
				let variables = {}
				variables.input = {
					id: changedStore.id,
					name: changedStore.name,
					addressService: changedStore.addressService,
					taxService: changedStore.taxService,

				}
				const res = await mediator.publish('graphqlFetch', updateStoreQuery, variables)
				ractive.set('originalStores', klona(ractive.get('stores')))
			},
			cancelChanges() {
				const ractive = this
				ractive.set('stores', klona(ractive.get('originalStores')))
			},
		},
		data: {
			stores: [],
			selectedStoreId: 0,
			currentStore: {},

		},
		async resolve(data, parameters) {
			const { stores } = await mediator.publish('graphqlFetch', storeQuery)
			const { session } = await mediator.publish('graphqlFetch', `query permissionQuery {
				session {
				  permissions {
					CONFIGURATION_CAN_CONFIGURE_STORES
				   }
				}
			  }`)

			return {
				originalStores: klona(stores),
				stores,
				canConfigureStores: session.permissions.CONFIGURATION_CAN_CONFIGURE_STORES,
			}
		},
		activate(context) {
			var ractive = context.domApi

			ractive.observe('selectedStoreId', storeId => {
				const currentStoreIndex = ractive.get('stores')
					.findIndex(store => store.id === storeId)

				ractive.link(`stores[${currentStoreIndex}]`, 'currentStore')
			}, { init: true })
		},
	})
}
