const enteredBy = `#graphql
enteredBy {
	id
	name
}`

export const shippingDimensions = `#graphql
shippingDimensions {
	weightUnit
	measurementUnit
	weight
	length
	width
	height
}`

const inventoryOptionValuesPartial = `#graphql
inventoryOptions: optionValues {
    inventoryOptionValueId: id
    optionId
    value
    option {
        id
        name
        defaultChoice
		required
        rank
		dataType
		public
        manufacturer {
    		id
        	name
        }
        model {
        	id
        	name
        }
        category {
        	id
        	name
        }
		inventoryType {
			id
		}
        choices {
        	default
        	label
        	rank
        }
    }
}`

export const inventoryReturnData = `#graphql
	innodbInventoryid: id
	attachments {
		fileId: id
		file {
			id
			createdDate: created
			extension
			mimeType: mimetype
			name
			path: url
			size
			type
			updated
		}
		public
		rank
	}
	averageCoreCost
	averageCost
	averageDemandPerDay
	averageDemandPerMonth
	bodyStyle
	buyPackage
	category {
		id
		description
		name
	}
	condition
	coreClass
	coreCost
	coreRequired
	coreRequiredToVendor
	cost
	cost
	dateEntered
	dateModified
	dateViewed
	daysToReturn
	daysToReturnCore
	daysToReturnCoreToVendor
	daysToReturnToVendor
	defaultVendor {
		id
		coreClassHistory
	}
	deplete
	description
	distributorCorePrice
	distributorPrice
	${enteredBy}
	freezeUntil
	glCategory {
		id
		name
	}
	inventoryId
	${inventoryOptionValuesPartial}
	inventoryTypeId
	jobberCorePrice
	jobberPrice
	jobberPrice
	interchangeNumber
	inventoryType {
		typeData1History
		typeData2History
		typeData3History
		typeData4History
		setId
		id
	}
	listPrice
	locations {
		id
		holdQuantity
		location {
			description
			allowInventory
		}
		locationId
		name
		permanent
		quantity
		rank
	}
	manufacturer {
		id
		name
	}
	manufacturerId
	maxQuantity
	minQuantity
	model {
		id
		name
	}
	modelId
	notes
	oemNumber
	parentModel {
		id
		name
	}
	parentManufacturer {
		id
		name
	}
	partNumber
	popularityCode
	printTag
	public
	quantity
	quantityAvailable
	quantityOnHold
	replenishable
	retailCorePrice
	retailCorePrice
	retailPrice
	retailPrice
	returnable
	returnableToVendor
	saleClass {
		code
	}
	serials(filter: $serialFilter) {
		id
		dateEntered
		enteredOnDocumentId
		enteredOnDocumentLineId
		enteredOnDocumentStoreId
		enteredOnDocumentType
		${inventoryOptionValuesPartial}
		location {
			... on Location {
				id
				name
			}
			... on VirtualLocation {
				name
			}
		}
		number
		status
		usedOnDocumentId
		usedOnDocumentStoreId
		usedOnDocumentType
	}
	safetyStockPercent
	seasonal
	sellPackage
	serialized
	side
	singleQuantity
	${shippingDimensions}
	sku
	status
	stockCategory
	stockingDays
	stockMethod
	stockType
	storeId
	storeId
	suggestedMaxQuantity
	suggestedMinQuantity
	suggestedSafetyStockPercent
	tagNumber
	taxable
	typeField1 {
		label
		data
	}
	typeField2 {
		label
		data
	}
	typeField3 {
		label
		data
	}
	typeField4 {
		label
		data
	}
	userStatus
	useVendorOrderMultiplier
	vehicle {
		make
		model
		vin
		year
		bodyStyle
		stockNumber
		topImage {
		url
		name
		}
	}
	vehicleId
	vehicleMake
	vehicleModel
	vehicleVin
	vehicleYear
	vendorLeadTime
	vendorPopularityCode
	vendorProductCode
	wholesaleCorePrice
	wholesalePrice
	wholesalePrice
`
