import makeItTable from '@isoftdata/table'
import makeItTd from '@isoftdata/td'
import makeItTableCardFooter from '@isoftdata/table-card-footer'
import makeImageThumbnailViewer from '@isoftdata/image-thumbnail-viewer'
import makeItImageViewerModal from '@isoftdata/image-viewer-modal'
import makeItButton from '@isoftdata/button'
import pProps from 'p-props'
import MakeItCheckbox from '@isoftdata/checkbox'
import { stringToBoolean } from '@isoftdata/utility-string'
import { format as formatCurrency } from '@isoftdata/utility-currency'
import template from './results.html'

function getOrderBy(column, direction) {
	let orderBy = column.property
	if (orderBy === 'attachments.length') {
		orderBy = 'attachmentCount'
	} else if (orderBy === 'category.name') {
		orderBy = 'category'
	} else if (orderBy === 'vehicle.bodyStyle') {
		orderBy = 'bodyStyle'
	} else if (orderBy.includes('typeField')) {
		const split = orderBy.split('.')
		orderBy = split[0]
	} else if (orderBy.includes('.') && orderBy !== '') {
		const split = orderBy.split('.')
		split[1] = split[1].charAt(0).toUpperCase() + split[1].slice(1)
		orderBy = split.join('')
	}
	orderBy = orderBy.concat('_', direction)
	console.log(orderBy)
	return orderBy
}

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.part-search.results',
		route: 'results',
		querystringParameters: [ 'selectedInventoryId', 'pageNumber', 'perPageCount' ],
		//querystringParameters: [ 'selectedInventoryId', 'pageNumber'],
		// If I add perPageCount to queryStringParameters, I don't see it
		// If I add a default value below, it shows up in parameters and I get one row, but dropdown is empty
		defaultParameters: {
			selectedInventoryId: null,
			pageNumber: 1,
			perPageCount: 10, // appears to affect first load at least
		},
		data: {
			formatCurrency,
		},
		template: {
			template,
			components: {
				itTable: makeItTable({
					methods: {
						columnClicked(column, direction) {
						//	console.log(column, direction)
							const orderBy = getOrderBy(column, direction)
							stateRouter.go('app.part-search.results', { orderBy, pageNumber: 1 }, { inherit: true })
						},
					},
					stickyHeader: true,
				}),
				itTd: makeItTd(),
				itTableCardFooter: makeItTableCardFooter(),
				imageThumbnailViewer: makeImageThumbnailViewer(),
				itImageViewerModal: makeItImageViewerModal(),
				itButton: makeItButton(),
				itCheckbox: MakeItCheckbox(),
			},
			computed: {

			},
		},
		resolve(data, parameters) {
		//	console.log("Data", data, "Parameters", parameters)
			return Promise.resolve({
				selectedInventoryId: parameters.selectedInventoryId,
				showThumbnails: stringToBoolean(localStorage.getItem('showThumbs') ?? true),
				pageNumber: Number(parameters.pageNumber),
				perPageCount: parseInt(localStorage.getItem('numPartRows') ?? parameters.perPageCount, 10),
				...data })
		},
		activate({ domApi: ractive }) {
			ractive.on('showImages', (context, attachments)=> {
				context.event.stopPropagation()
				ractive.set({ imageViewerShown: true, imageViewerImages: [], currentImageViewerImageIndex: 0 })
				const imageViewerImages = attachments
					.filter(attachment => attachment.file.mimetype.startsWith('image/'))
					.map(attachment => attachment.file.path)
				ractive.set({ imageViewerImages })
			})
			ractive.on('edit-inventory-clicked', (context, inventoryId) =>{
				stateRouter.go('app.part', { inventoryId })
			})

			ractive.on('showThumbnailsClicked', (context, show) => {
				localStorage.setItem('showThumbs', show)
			})

			ractive.on('numPartRowsChanged', (context, data) => {
				// Save to local storage
				localStorage.setItem('numPartRows', data.perPageCount)

				// Resubmit search, doesn't make sense to resubmit search with existing pageNumber
				stateRouter.go('app.part-search.results', { perPageCount: data.perPageCount, pageNumber: 1 }, { inherit: true })
			})

			ractive.on('itTable.paginationPageChange', ({ pageNumber }) => {
				stateRouter.go('app.part-search.results', { pageNumber }, { inherit: true })
			})
		},
	})
}
