import makeItModal from '@isoftdata/modal'
import makeItTable from '@isoftdata/table'
import makeItInput from '@isoftdata/input'
import makeItCurrencyInput from '@isoftdata/currency-input'
import makeItSelect from '@isoftdata/select'
import makeItCheckbox from '@isoftdata/checkbox'
import makeItButton from '@isoftdata/button'
import template from './custom-question.html'
import { inventoryOptionsQuery, partManufacturersQuery, partModelsQuery, categoriesQuery, inventoryOptionFields } from 'graphql-queries'
import { booleanToYesNo as yesNo } from '@isoftdata/utility-string'
import { klona } from 'klona'
import pProps from 'p-props'

const defaultQuestion = Object.freeze({
	id: null,
	name: '',
	inventoryTypeId: null,
	manufacturerId: '',
	modelId: '',
	categoryName: '',
	dataType: 'TEXT',
	public: true,
	required: false,
	choiceQuery: '',
	choices: [],
})

const defaultQuestionModalState = Object.freeze({
	shown: false,
	title: 'Edit Custom Question',
	isNew: false,
	isSaving: false,
	question: klona(defaultQuestion),
	partManufacturerList: [],
	partModelList: [],
	categoryList: [],
	isLoadingManModCat: false,
	addingChoiceQuery: false,
	isTestingChoiceQuery: false,
	choiceQueryTestResult: {
		success: null,
		error: null,
	},
})

const dataTypesMap = new Map([
	[ 'TEXT', 'Text' ],
	[ 'AUTOSUGGEST_TEXT', 'Autosuggest Text' ],
	[ 'CHOICE', 'Choice' ],
	[ 'NUMBER', 'Number' ],
	[ 'CURRENCY', 'Currency' ],
	[ 'DATE', 'Date' ],
	[ 'TIME', 'Time' ],
	[ 'DATETIME', 'Datetime' ],
	[ 'BOOLEAN', 'Boolean' ],
])

function hasDuplicateChoices(choices) {
	if (choices.length > 0) {
		const choiceNames = choices.map(choice => choice.label)
		return choices.length !== Array.from(new Set(choiceNames)).length
	} else {
		return false
	}
}

export default function({ mediator, stateRouter }) {
	async function loadPartManufacturers(inventoryTypeId) {
		inventoryTypeId = parseInt(inventoryTypeId, 10)

		const { inventoryType: { partManufacturers } } = await mediator.publish('graphqlFetch', partManufacturersQuery, { inventoryTypeId })
		return partManufacturers
	}

	async function loadPartModels(inventoryTypeId, manufacturerId) {
		inventoryTypeId = parseInt(inventoryTypeId, 10)
		manufacturerId = parseInt(manufacturerId, 10)

		const { manufacturer: { models } } = await mediator.publish('graphqlFetch', partModelsQuery, { inventoryTypeId, manufacturerId, active: true })
		return models
	}

	async function loadCategories(inventoryTypeId) {
		inventoryTypeId = parseInt(inventoryTypeId, 10)

		if (inventoryTypeId) {
			const { inventoryType: { categories } } = await mediator.publish('graphqlFetch', categoriesQuery, { inventoryTypeId })
			return categories
		} else {
			return null
		}
	}

	async function loadManModCat({ inventoryTypeId, manufacturerId }) {
		return await pProps({
			manufacturers: inventoryTypeId ? loadPartManufacturers(inventoryTypeId) : [],
			models: (inventoryTypeId && manufacturerId) ? loadPartModels(inventoryTypeId, manufacturerId) : [],
			categories: inventoryTypeId ? loadCategories(inventoryTypeId) : [],
		})
	}

	stateRouter.addState({
		name: 'app.configuration.inventory-type.custom-question',
		route: 'custom-question',
		querystringParameters: [ 'inventoryTypeId' ],
		template: {
			template,
			components: {
				itTable: makeItTable({ stickyHeader: true }),
				itInput: makeItInput({ twoway: true, lazy: false }),
				itSelect: makeItSelect({ twoway: true, lazy: false }),
				itModal: makeItModal(),
				itCheckbox: makeItCheckbox(),
				itCurrencyInput: makeItCurrencyInput({ twoway: true }),
				itButton: makeItButton(),
			},
			computed: {
				displayQuestions() {
					let questions = this.get('questions').map(question => {
						return {
							...question,
							inventoryTypeId: question.inventoryType?.id || null,
							typeName: question.inventoryType === null ? 'All Types' : (`${question.inventoryType?.name} - ${question.inventoryType?.id}`),
							manufacturerName: question.manufacturer?.name,
							modelName: question.model?.name,
							categoryName: question.category?.name,
						}
					})

					if (!this.get('showGlobalQuestions')) {
						questions = questions.filter(question => question.inventoryTypeId !== null)
					}

					return questions
				},
			},
			yesNo,
			dataTypesMap,
			async inventoryTypeChanged(inventoryTypeId) {
				this.set({ 'editQuestionModal.isLoadingManModCat': true })
				const { manufacturers, models, categories } = await loadManModCat({
					inventoryTypeId,
					manufacturerId: this.get('editQuestionModal.question.manufacturer'),
				})

				this.set({
					'editQuestionModal.partManufacturerList': manufacturers,
					'editQuestionModal.partModelList': models,
					'editQuestionModal.categoryList': categories,
					'editQuestionModal.isLoadingManModCat': false,
				})
			},
			async manufacturerChanged(manufacturerId) {
				console.log(manufacturerId)
				this.set({ 'editQuestionModal.isLoadingManModCat': true })
				const manufacturerList = await loadPartModels(this.get('editQuestionModal.question.inventoryTypeId'), manufacturerId)
				this.set({
					'editQuestionModal.partModelList': manufacturerList,
					'editQuestionModal.isLoadingManModCat': false,
				})
			},
			selectQuestion(question) {
				return this.set('selectedQuestion', this.get('selectedQuestion')?.id !== question?.id ? question : null)
			},
			async addQuestion() {
				const ractive = this

				ractive.set({
					editQuestionModal: klona({
						...defaultQuestionModalState,
						isNew: true,
						title: 'Add Custom Question',
						shown: true,
						question: {
							...defaultQuestion,
							inventoryTypeId: ractive.get('selectedInventoryTypeId'),
						},
						isLoadingManModCat: true,
					}),
				})

				await ractive.loadEditQuestionModal()
			},
			async editQuestion({ id, name, inventoryTypeId, manufacturer, model, category, dataType, public: isPublic, required, choiceQuery, choices }) {
				const ractive = this

				ractive.set({
					editQuestionModal: klona({
						...defaultQuestionModalState,
						shown: true,
						question: {
							id,
							name,
							inventoryTypeId: inventoryTypeId === null ? '' : inventoryTypeId,
							manufacturerId: manufacturer?.id || '',
							modelId: model?.id || '',
							categoryName: category?.name || '',
							dataType,
							public: isPublic,
							required,
							choiceQuery: choiceQuery || '',
							choices: choices || [],
						},
						isLoadingManModCat: true,
					}),
				})

				await ractive.loadEditQuestionModal()
			},
			async loadEditQuestionModal() {
				const ractive = this
				//Apply a special, empty choice as a placeholder of sorts
				if (ractive.get('editQuestionModal.question.choices').length === 0) {
					//Apply a special, empty choice as a placeholder of sorts
					ractive.addChoice({ isPlaceholder: true }, false)
				}

				const {
					manufacturers: partManufacturerList,
					models: partModelList,
					categories: categoryList,
				} = await loadManModCat(ractive.get('editQuestionModal.question'))

				await ractive.set({
					editQuestionModal: {
						...this.get('editQuestionModal'),
						partManufacturerList,
						partModelList,
						categoryList,
						isLoadingManModCat: false,
					},
				})

				ractive.find('#questionNameInput').focus()
			},
			async addChoice(defaultChoice = {}, focus = true) {
				await this.push('editQuestionModal.question.choices', { label: '', isDefault: false, isPlaceholder: false, action: 'CREATE', ...defaultChoice })
				if (focus) {
					this.find(`#choice${this.get('editQuestionModal.question.choices').length - 1}`)?.focus?.()
				}
			},
			async addChoiceQuery() {
				await this.set('editQuestionModal.addingChoiceQuery', true)
				this.find('#choiceQueryInput')?.focus?.()
			},
			removeChoice(index) {
				this.splice('editQuestionModal.question.choices', index, 1, { ...this.get('editQuestionModal.question.choices')[index], action: 'DELETE' })
			},
			async testChoiceQuery(query) {
				console.log(query)
				this.set({ 'editQuestionModal.isTestingChoiceQuery': true })
				try {
					const { testSelectQuery } = await mediator.publish('graphqlFetch', `#graphql
						query Query($query: String!) {
							testSelectQuery(query: $query)
						}
					`, { query })
					this.set({
						'editQuestionModal.isTestingChoiceQuery': false,
						'editQuestionModal.choiceQueryTestResult': {
							success: testSelectQuery,
							error: null,
						},
					})
				} catch (err) {
					this.set({
						'editQuestionModal.isTestingChoiceQuery': false,
						'editQuestionModal.choiceQueryTestResult': {
							success: false,
							error: JSON.stringify(err, null, 2),
						},
					})
				} finally {
					this.set({ 'editQuestionModal.isTestingChoiceQuery': false })
				}
			},
			async saveQuestion(question, isNew = true) {
				const ractive = this
				//Remove any placeholder choices
				const choices = question.choices.filter(choice => !choice.isPlaceholder)

				if (hasDuplicateChoices(choices)) {
					return alert('Duplicate choices found. Please ensure all choices are unique.')
				}

				console.log(choices)
				const input = {
					required: question.required,
					public: question.public,
					name: question.name,
					inventoryTypeId: parseInt(question.inventoryTypeId, 10) || null,
					modelId: parseInt(question.modelId, 10) || null,
					manufacturerId: parseInt(question.manufacturerId, 10) || null,
					categoryName: question.categoryName || null,
					dataType: question.dataType,
					choiceQuery: question.choiceQuery ? question.choiceQuery.trim() : undefined,
					choices: choices.reduce((acc, choice) => {
						if (!choice.isPlaceholder && choice.action) {
							return [
								...acc,
								{
									id: choice.id,
									label: choice.label,
									isDefault: choice.isDefault,
									action: choice.action,
								},
							]
						}
						return acc
					}, []),
				}

				try {
					ractive.set('editQuestionModal.isSaving', true)
					let savedQuestion
					if (isNew) {
						({ createInventoryOption: savedQuestion } = await mediator.publish('graphqlFetch', `#graphql
							mutation CreateInventoryOption($input: NewInventoryOption) {
								createInventoryOption(input: $input) {
									id
								}
							}
						`, { input }))
					} else {
						({ createInventoryOption: savedQuestion } = await mediator.publish('graphqlFetch', `#graphql
							mutation UpdateInventoryOption($input: InventoryOptionUpdate!) {
								updateInventoryOption(input: $input) {
									id
								}
							}
						`, { input: { id: question.id, ...input } }))
					}
					//await ractive.upsert('questions', 'id', savedQuestion)
					ractive.set({
						'selectedQuestion': question,
						'editQuestionModal.isSaving': false,
						'editQuestionModal.shown': false,
					})
				} catch (err) {
					alert('An error occured saving the question. Please contact ISoft support.')
				}
			},
		},
		async resolve(_data, { inventoryTypeId }) {
			const { inventoryOptions: questions } = await mediator.publish('graphqlFetch', inventoryOptionsQuery, {
				inventoryTypeId: parseInt(inventoryTypeId, 10) || null,
				suppressFixedChoices: false,
				suppressQueryChoices: true,
			})

			return {
				questions,
				columns: [
					{ property: 'name', name: 'Question' },
					{ property: 'typeName', name: 'Type' },
					{ property: 'manufacturer', name: 'Manufacturer' },
					{ property: 'model', name: 'Model' },
					{ property: 'category', name: 'Category' },
					{ property: 'dataType', name: 'Data Type' },
					{ property: 'public', name: 'Public' },
					{ property: 'required', name: 'Required' },
					{ property: 'choiceQuery', name: 'Choice Query' },
				],
				selectedQuestion: null,
				editQuestionModal: klona(defaultQuestionModalState),
				selectedInventoryTypeId: inventoryTypeId || null,
				inventoryTypes: [],
				dataTypes: Array.from(dataTypesMap.keys()),
				showGlobalQuestions: true,
			}
		},
		activate({ domApi: ractive }) {
			mediator.publish('graphqlFetch', `#graphql
				query InventoryTypes {
					inventoryTypes { id name vehicle }
				}
			`).then(({ inventoryTypes }) => ractive.set({ inventoryTypes }))

			ractive.observe('editQuestionModal.question.choices.*', (choice, _oldChoice, keypath) => {
				//Watch the choices and if a placeholder choice gets its label changed, mark it as no longer a placeholder
				if (choice.isPlaceholder && choice.label !== '') {
					ractive.set(`${keypath}.isPlaceholder`, false)
				}
			}, { init: false, defer: true })

			ractive.on('choiceChanged', (_event, index) => {
				const keypath = `editQuestionModal.question.choices.${index}.action`
				//if we already have an action set, don't overwrite it
				if (ractive.get(keypath) !== 'CREATE') {
					ractive.set(keypath, 'UPDATE')
				}
			})

			ractive.observe('editQuestionModal.question.choices', v => console.log(v))
		},
	})
}
