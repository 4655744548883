import makeStateRouter from 'abstract-state-router'
import makeRactiveRenderer from 'ractive-state-router'
import pProps from 'p-props'
import mannish from 'mannish'
import globbed from './globbed'
import currency from '@isoftdata/utility-currency'
import { stringToBoolean } from '@isoftdata/utility-string'
import { booleanToString } from '@isoftdata/utility-boolean'
import toTitleCase from 'to-title-case'
import makeSidebar from '@isoftdata/sidebar-component'
import makeItAlert from '@isoftdata/alert'
import { getBarcodeType } from 'barcode-helper'
import onBarcodeScan from '@isoftdata/scanner-support'
import utilityStorage from '@isoftdata/utility-storage'
const { getObject } = utilityStorage
onBarcodeScan()
import { checkSessionPermission } from 'permission'

// eslint-disable-next-line no-undef
const microservicehost = typeof microservicehosturl != 'undefined' ? microservicehosturl : ''
// eslint-disable-next-line no-undef
const graphqlhostversion = typeof graphqlversion != 'undefined' ? graphqlversion : 'graphql'

const imgURL = microservicehost
const ractiveRenderer = makeRactiveRenderer(Ractive, {
	data: {
		toLocaleDateString(dateString) {
			if (dateString === null || dateString === undefined) {
				return ''
			} //otherwise, toLocaleDateString will return the start of unix time
			dateString = dateString.length === 10 ? `${dateString }T23:59:59Z` : dateString//mad hax bro
			return new Date(dateString).toLocaleDateString()
		},
		formatCurrency(decimalString, options) {
			//pass false for includeSymbol option if you're calling this for an input,
			//like the examples shown here: https://getbootstrap.com/docs/4.0/components/forms/#inline-forms
			return currency.format(decimalString, options)
		},
		getValidWebsiteURL(url) {
			if (!url.startsWith('http'))	{
				return `http://${ url}`
			}
			return url
		},
		stringToBoolean: str => {
			return stringToBoolean(str)
		},
		booleanToString: bool => {
			return booleanToString(bool)
		},
		getImgHostURL: imgPath => {
			return `${imgURL}${imgPath}`
		},
		getImgURL: img => {
			return `${imgURL}/file/${img.id}/${img.name}`
		},
		checkSessionPermission,
	},
	checkSessionPermission,
})

const stateRouter = makeStateRouter(ractiveRenderer, 'body')
const context = {
	stateRouter,
	apiURL: `${microservicehost}/${graphqlhostversion}`,
	mediator: mannish(),
	isChildState: ({ childState, parentState }) => childState.indexOf(parentState) > -1,
	checkSessionPermission,
}

import ractivePartialNoItemsForSelectedFilter from 'partials/noItemsForSelectedFilter.html'
import ractiveSelectOnFocus from 'ractive-select-on-focus'
import ractiveMethodUpsert from '@isoftdata/ractive-method-upsert'
import ractiveDecoratorValid from '@isoftdata/ractive-decorator-valid'

Ractive.partials.noItemsForSelectedFilter = ractivePartialNoItemsForSelectedFilter
Ractive.decorators.selectOnFocus = ractiveSelectOnFocus
Ractive.decorators.valid = ractiveDecoratorValid
Ractive.decorators.focus = node => {
	node.focus()
	return {
		teardown() {},
	}
}
Ractive.defaults.upsert = ractiveMethodUpsert

import template from './app.html'

stateRouter.addState({
	name: 'app',
	template: {
		template,
		components: {
			sidebar: makeSidebar(stateRouter),
			itAlert: makeItAlert(),
		},
		stateRouterGo: stateRouter.go,
		data: {
			stateIsChanging: false,
			alertIsShown: false,
		},
	},
	defaultChild: 'vehicle-search',
	querystringParameters: [ 'sessionToken', 'sessionId', 'appLayout' ],
	async resolve(data, parameters) {
		const user = JSON.parse(sessionStorage.getItem('user'))
		const recentActivity = JSON.parse(localStorage.getItem('recentActivity') || '{}')
		const currentUserRecentActivity = recentActivity[user?.id] || []

		//	console.log(user)
		if (!user) {
			throw { redirectTo: { name: 'login' } }
		}

		return await pProps({
			user,
			appLayout: localStorage.getItem('appLayout') || 'teardown',
			states: [
				{
					type: 'DASHBOARD',
					name: 'Dashboard',
					route: 'app.dashboard',
					icon: 'fa-chart-line',
					hidden: !user.sessionPermissions.DASHBOARD_REPORT_VIEW, // TODO: Permission, hide in 'teardown mode'?
				},
				{
					type: 'PART',
					name: 'Part',
					route: 'app.part-search',
					icon: 'fa-engine',
					hidden: !checkSessionPermission('PARTS_CAN_VIEW_PARTS'),
					additionalActiveRoutes: [
						{ route: 'app.part' },
					],
					createRoute: { route: 'app.part' },
					isNew: true,
				},
				{
					type: 'VEHICLE',
					name: 'Vehicle',
					route: 'app.vehicle-search',
					icon: 'fa-truck',
					additionalActiveRoutes: [
						{ route: 'app.vehicle' },
					],
					createRoute: { route: 'app.vehicle' },
				},
			],
			recentActivity: currentUserRecentActivity,
			sidebarCollapsed: JSON.parse(localStorage.getItem('sidebarCollapsed')) || false, //TODO: make this stored per user
		})
	},
	activate(stateRouterContext) {
		const ractive = stateRouterContext.domApi
		//console.log(ractive.get('user'))

		ractive.observe('sidebarCollapsed', sidebarCollapsed => {
			//console.log(sidebarCollapsed)
			localStorage.setItem('sidebarCollapsed', sidebarCollapsed)
		}, { init: false })

		ractive.on('appLayoutChange', appLayout => {
			localStorage.setItem('appLayout', appLayout)
			ractive.set('appLayout', appLayout)

			stateRouter.go(null, { appLayout }, { inherit: true })
		})

		stateRouter.on('routeNotFound', function(route, parameters) {
			stateRouter.go('not-found', {
				route,
				parameters,
			})
		})

		stateRouter.on('stateChangeAttempt', () => {
			const user = JSON.parse(sessionStorage.getItem('user'))
			if (!user) {
				return stateRouter.go('login')
			}
		})

		stateRouter.on('stateChangeStart', (state, parameters) => {
			ractive.set({
				destinationStateName: state.name,
				stateIsChanging: true,
			})
		})

		const removeActivityProvider = context.mediator.provide('activity', activity => {
			const currentActivity = ractive.findComponent('sidebar').newActivity(activity)
			const allRecentActivity = JSON.parse(localStorage.getItem('recentActivity') || '{}')

			allRecentActivity[ractive.get('user.id')] = currentActivity
			localStorage.setItem('recentActivity', JSON.stringify(allRecentActivity))
		})

		const removeRemoveActivityProvider = context.mediator.provide('removeActivity', activityTitle => {
			const userId = ractive.get('user.id')
			const userActivity = ractive.get('recentActivity')
			const filteredUserActivity = userActivity.filter(activity => activity.displayTitle !== activityTitle)

			const allRecentActivity = JSON.parse(localStorage.getItem('recentActivity') || '{}')

			allRecentActivity[userId] = filteredUserActivity
			localStorage.setItem('recentActivity', JSON.stringify(allRecentActivity))
			ractive.set('recentActivity', filteredUserActivity)
		})

		const removeHideMessageProvider = context.mediator.provide('hideMessage', () => {
			ractive.findComponent('itAlert').hide()
		})

		const removeShowMessageProvider = context.mediator.provide('showMessage', (options = {}) => {
			let { type, time, dismissable, ...otherOptions } = options

			const alertClass = `alert-${(type && type.toLowerCase().trim()) || 'secondary'}`

			ractive.findComponent('itAlert').show({
				class: alertClass,
				...otherOptions,
				dismissable: dismissable !== false, //if they don't pass a value for dismissable, make sure we default it to true
			})

			if (time !== false) {
				let timeoutId
				timeoutId = setTimeout(() => {
					ractive.findComponent('itAlert').hide()
				}, time || 3000)

				ractive.findComponent('itAlert')
					.on('alertDismissed', () => {
						if (timeoutId) {
							clearTimeout(timeoutId)
						}
					})
			}
		})

		stateRouter.on('stateChangeError', err => {
			const stateName = ractive.get('destinationStateName')
			ractive.set({ stateIsChanging: false, destinationStateName: false })
			context.mediator.publish('showMessage', { type: 'danger', time: false, heading: `Error: State change to ${stateName} failed.`, message: err.message })
		})

		stateRouter.on('stateChangeEnd', (state, _parameters, _states) => {
			let routeName = state.name

			if (routeName.startsWith('app.')) {
				routeName = routeName.substring(4)
			}

			const stateNameArray = routeName.split('.')
			let stateName = ''
			stateNameArray.forEach(state => {
				stateName += ` > ${ toTitleCase(state)}`
			})

			document.title = `ITrack Enterprise ${ stateName}`

			ractive.set({
				destinationStateName: false,
				stateIsChanging: false,
			})
			window.processBarcode = async barcodeInput => {
				let barcode = getBarcodeType(barcodeInput)

				//This modal won't go away if the user scans a barcode that results in `go`ing to the same state, so I'm doing away with it for now.
				//ractive.set({ showLoadingModal: true })
				if (barcode.type === 'VIN') {
				//	alert('VIN', barcode.value)
					const vehicleByVinQuery = `#graphql
						query LIB_vehicleinventory($vin: String) {
							vehicles(search: $vin) {
								items {
									vehicleId: id
								}
							}
						}`

					const vin = barcode.value
					const lookup = await context.mediator.publish('graphqlFetch', vehicleByVinQuery, { vin })

					if (lookup.vehicles.items.length > 0) {
						if (lookup.vehicles.items.length == 1) {
							stateRouter.go('app.vehicle', { vehicleId: lookup.vehicles.items[0].vehicleId })
						} else {
							stateRouter.go('app.vehicle-search', { searchQuery: vin })
						}
					} else {
						alert(`No vehicle currently with VIN: ${vin}`)
					}
				} else if (barcode.type === 'SERIAL_ID') {
					const storeId = JSON.parse(sessionStorage.getItem('user')).currentStore
					const lookupBySerialQuery = `#graphql
							query Inventory($inventorySerialId: Int!) {
									inventorySerial(id: $inventorySerialId) {
										number
										inventory {
											id
											storeId
										}
									}
								}`

					const inventorySerialId = parseInt(barcode.value, 10)
					const results = await context.mediator.publish('graphqlFetch', lookupBySerialQuery, { inventorySerialId })

					if (results.inventorySerial) {
						if (results.inventorySerial.inventory.storeId === storeId) {
							stateRouter.go('app.part', { inventoryId: results.inventorySerial.inventory.id })
						} else {
							alert(`Serial Number "${results.inventorySerial.number}" not found at store: ${storeId}`)
						}
					} else {
						alert(`Serial ID "${inventorySerialId}" not found.`)
					}
				} else if (barcode.type === 'INVENTORY_ID' || barcode.type === 'UPC' || barcode.type === 'OTHER') {
					const storeId = JSON.parse(sessionStorage.getItem('user')).currentStore
					let skuResults = {}
					if (barcode.type === 'INVENTORY_ID') {
						const lookupSKUquery = `#graphql
							query InventoryBySkuAndStore($sku: Int!, $storeId: Int!) {
								inventoryBySkuAndStore(sku: $sku, storeId: $storeId) {
								id
								vehicleId
								inventoryType {
									vehicle
								}
								}
							}`

						const sku = parseInt(barcode.value, 10)
						skuResults = await context.mediator.publish('graphqlFetch', lookupSKUquery, { sku, storeId })
					}

					if (skuResults.inventoryBySkuAndStore) {
						if (skuResults.inventoryBySkuAndStore.inventoryType.vehicle) {
							stateRouter.go('app.vehicle', { vehicleId: skuResults.inventoryBySkuAndStore.vehicleId })
						} else {
							stateRouter.go('app.part', { inventoryId: skuResults.inventoryBySkuAndStore.id })
						}
					} else {
						const lookupSKUquery = `#graphql
						query SkuLookup($input: SkuLookupResolverInput!) {
							skuLookup(input: $input) {
								useInScanner
								relationshipType
								packageQuantity
								sku: inventoryId
								inventories {
								id
								storeId
								vehicleId
								inventoryType {
									vehicle
								}
								}
							}
							}`

						const lookup = barcode.value
						const results = await context.mediator.publish('graphqlFetch', lookupSKUquery, { input: { lookup } })
						console.log(results)

						if (results.skuLookup.length > 1) {
							alert(`Multiple found for  ${lookup}`)
						} else if (results.skuLookup.length == 1) {
							const inventoryItem = results.skuLookup[0].inventories.find(inventoryItem => inventoryItem.storeId == storeId)

							if (inventoryItem) {
								if (inventoryItem.inventoryType.vehicle) {
									stateRouter.go('app.vehicle', { vehicleId: inventoryItem.vehicleId })
								} else {
									stateRouter.go('app.part', { inventoryId: inventoryItem.id })
								}
							} else {
								alert(`No inventory found for Lookup: ${lookup} at store: ${storeId}`)
							}
						} else {
							alert(`Nothing found for ${barcode.type} - ${lookup}`)
						}
						//alert(`No inventory found for SKU: ${sku} at store: ${storeId}`)
					}
				} else {
					alert(`Barcode Type  ${barcode.type} Not Implemented ${barcode.value}`)
				}
			}

			const scannerOptions = getObject(localStorage, 'scannerOptions') || {
				preamble: '~',
				postamble: [ 13 ],
			}

			onBarcodeScan(window.processBarcode, scannerOptions)
		})

		// Mostly functional link click based route guards- disabled for now
		/* 		const removeAnchorClickListener = browserEvent(document, 'click', event => {
			if (event.target.closest('a') && routeGuards[stateRouter.getActiveState().name] && !routeGuards[stateRouter.getActiveState().name](event)) {
				event.preventDefault()
				console.log('guarded!')
			}
		}) */

		stateRouterContext.on('destroy', function() {
			removeShowMessageProvider()
			removeHideMessageProvider()
			removeActivityProvider()
			removeRemoveActivityProvider()
			// removeAnchorClickListener()
		})
	},
})

globbed.states.forEach(createState => {
	if (typeof createState === 'function') {
		createState(context)
	} else if (typeof createState === 'object') {
		createState.default(context)
	}
})

globbed.services.forEach(service => service(context))
stateRouter.evaluateCurrentRoute('app')

