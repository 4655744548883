import debugLog from 'debug'
debugLog('enterpriseweb:login')
import { storesForLoginQuery, loginQuery } from 'graphql-queries'
import makeLoginComponent from '@isoftdata/login-component'
import template from './login.html'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'login',
		route: 'login',
		querystringParameters: [],
		data: {

		},
		template: {
			template,
			components: {
				login: makeLoginComponent(),
			},
		},
		async resolve(_data, _parameters) {
			sessionStorage.removeItem('user')
			if (localStorage.getItem('authToken')) {
				try {
					await mediator.publish('graphqlFetch', 'mutation CloseSessionMutation {closeSession}', null)
				} catch (e) {
					console.log('yikes')
				}
			}
			localStorage.removeItem('authToken')
			const lastUsername = localStorage.getItem('lastUsername')
			const lastStoreId = localStorage.getItem('lastStoreId')

			const res = await mediator.publish('graphqlFetch', storesForLoginQuery, null)

			return {
				storeList: res?.storesForLogin || [],
				selectedStoreId: lastStoreId || res.storesForLogin[0].id,
				username: lastUsername || '',
				isLoading: false,
			}
		},
		activate(context) {
			const ractive = context.domApi

			function saveRememberMe(username, storeId) {
				if (ractive.get('rememberMe')) {
					localStorage.setItem('lastUsername', username)
				} else {
					localStorage.removeItem('lastUsername')
				}
			}

			ractive.on('login', async({ username, password, siteId }, event) => {
				debugLog('login fired')
				ractive.set({ isLoading: true })
				try {
					const { createSession: userSession } = await mediator.publish('graphqlFetch', loginQuery, { username, passphrase: password, selectedStoreId: parseInt(siteId, 10) })
					debugLog(userSession)

					localStorage.setItem('lastStoreId', siteId)
					saveRememberMe(username, siteId)

					localStorage.setItem('authToken', userSession.token)
					/* check if user has WEB_CAN_LOGIN*/

					if (userSession.permissions.WEB_CAN_LOGIN) {
						const user = {
							...userSession.userAccount,
							currentStore: userSession.storeId,
							sessionPermissions: userSession.permissions,
						}

						sessionStorage.setItem('user', JSON.stringify(user))

						stateRouter.go('app')
					} else {
						//need to cancel session
						await mediator.publish('graphqlFetch', `mutation CloseSessionMutation {closeSession}`, null)
						localStorage.removeItem('authToken')
						ractive.set({ isLoading: false })
						ractive.findComponent('login').invalidLogin("User does not have login permission for Enterprise Web")
					}
				} catch (err) {
					ractive.set({ isLoading: false })
					err.forEach(error => {
						console.error(error.message)
					})
					ractive.findComponent('login').invalidLogin(err?.[0]?.message || 'An unknown error occured')
				}
			})
		},
	})
}
