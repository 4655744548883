import pProps from 'p-props'
import { sortArrayByObjectKey } from '@isoftdata/utility-array'
import { vehicleMakes, vehiclequery, settingValuesQuery, storeLocations, vehiclePermissions, vehicleTypesQuery } from 'graphql-queries'
import template from './vehicle.html'

let removeVehicleSaveMediator = false

export default function(appContext) {
	const mediator = appContext.mediator
	const stateName = 'app.vehicle'

	appContext.stateRouter.addState({
		name: 'app.vehicle',
		route: 'vehicle',
		defaultChild: 'basic',
		querystringParameters: [ 'vehicleId', 'storeId', 'appLayout' ],
		defaultParameters: { 'storeId': 1 },
		template: {
			template,
		},
		data: {

		},
		async resolve(data, parameters) {
			let vehicleId = parseInt(parameters.vehicleId, 10)
			let storeId = parseInt(parameters.storeId, 10)
			/*	const asOptionObject = array => {
				return	array.locations.reduce((acc, location) => {
					return { ...acc, [location.name]: location.name }
				}, {})
			}*/

			return await pProps({
				vehicle: parameters.vehicleId ? (await mediator.publish('graphqlFetch', vehiclequery, { vehicleId }))?.vehicle : {},
				vehicleMakes: mediator.publish('graphqlFetchWithCache', { query: vehicleMakes, mutator: res=> res?.vehicleMakes }),
				settingValues: await mediator.publish('graphqlFetchPath', settingValuesQuery, {}, 'settingValues'),
				vehiclePermissions: await mediator.publish('graphqlFetchPath', vehiclePermissions, {}, 'session.permissions'),
				vehicleTypes: mediator.publish('graphqlFetchWithCache', { query: vehicleTypesQuery, mutator: res => sortArrayByObjectKey({ array: res.vehicleTypes, key: 'name' }) }),
				locations: await mediator.publish('graphqlFetchWithCache', { query: storeLocations, variables: { storeId }, minutesToLive: 300, mutator: res => res?.locations }),
				vehicleId: parameters.vehicleId,
				storeId: parameters.storeId,
			})
		},
		activate(context) {
			const { domApi: ractive, parameters } = context

			if (!removeVehicleSaveMediator) {
				removeVehicleSaveMediator = mediator.provide('vehicleSave', ({ stateThatSaved, data }) => {
					console.log(stateThatSaved, data)
					if (appContext.isChildState({ childState: stateThatSaved, parentState: stateName })) {
						ractive.set({
							saved: new Date().toString(),
							vehicle: data,
						})
					}
				})
			}

			ractive.on('NEW_VEHICLE', () => {
				appContext.stateRouter.go(null, {})
			})

			if (ractive.get('vehicle.stockNumber')) {
				mediator.publish('activity', {
					stateName,
					stateParameters: parameters,
					stateCategory: 'VEHICLE',
					action: 'VIEW',
					displayTitle: `#${ractive.get('vehicle.stockNumber')}`,
					stateParameterKey: 'vehicleId',
					icon: 'fa-truck',
				})
			}
		},
	})
}

