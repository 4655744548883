import states_configuration_configuration from './states/configuration/configuration'
import states_dashboard_dashboard from './states/dashboard/dashboard'
import states_dummy_dummy from './states/dummy/dummy'
import states_error_error from './states/error/error'
import states_login_login from './states/login/login'
import states_not_found_not_found from './states/not-found/not-found'
import states_part_search_part_search from './states/part-search/part-search'
import states_part_part from './states/part/part'
import states_vehicle_search_vehicle_search from './states/vehicle-search/vehicle-search'
import states_vehicle_vehicle from './states/vehicle/vehicle'
import states_configuration_dashboard_dashboard from './states/configuration/dashboard/dashboard'
import states_configuration_device_device from './states/configuration/device/device'
import states_configuration_inventory_type_inventory_type from './states/configuration/inventory-type/inventory-type'
import states_configuration_store_store from './states/configuration/store/store'
import states_configuration_user_user from './states/configuration/user/user'
import states_part_search_results_results from './states/part-search/results/results'
import states_vehicle_search_results_results from './states/vehicle-search/results/results'
import states_vehicle_attachment_attachment from './states/vehicle/attachment/attachment'
import states_vehicle_basic_basic from './states/vehicle/basic/basic'
import states_vehicle_teardown_teardown from './states/vehicle/teardown/teardown'
import states_configuration_dashboard_manage_manage from './states/configuration/dashboard/manage/manage'
import states_configuration_inventory_type_custom_question_custom_question from './states/configuration/inventory-type/custom-question/custom-question'
import services_emit_to_api_server from './services/emit-to-api-server'

export default {
	states: [
		states_configuration_configuration,
		states_dashboard_dashboard,
		states_dummy_dummy,
		states_error_error,
		states_login_login,
		states_not_found_not_found,
		states_part_search_part_search,
		states_part_part,
		states_vehicle_search_vehicle_search,
		states_vehicle_vehicle,
		states_configuration_dashboard_dashboard,
		states_configuration_device_device,
		states_configuration_inventory_type_inventory_type,
		states_configuration_store_store,
		states_configuration_user_user,
		states_part_search_results_results,
		states_vehicle_search_results_results,
		states_vehicle_attachment_attachment,
		states_vehicle_basic_basic,
		states_vehicle_teardown_teardown,
		states_configuration_dashboard_manage_manage,
		states_configuration_inventory_type_custom_question_custom_question,
	],
	services: [
		services_emit_to_api_server,
	],
}
