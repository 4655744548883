import makeNavTabBar from '@isoftdata/nav-tab-bar-component'
import template from './configuration.html'
import { checkSessionPermission } from 'permission'

function getChildStates() {
	const user = JSON.parse(sessionStorage.getItem('user'))
	return [
		{
			icon: 'chart-line',
			title: 'Dashboard',
			stateName: 'app.configuration.dashboard',
			stateParameters: {},
			hidden: !checkSessionPermission('DASHBOARD_REPORT_EDIT_ALL') && !checkSessionPermission('DASHBOARD_REPORT_EDIT_OWN'),
		},
		{
			icon: 'store-alt',
			title: 'Store',
			stateName: 'app.configuration.store',
			stateParameters: {},
			hidden: !user.settings.preferences.optInToTestingFeatures && !checkSessionPermission('STORE_CONFIGURATION_CAN_EDIT_STORES'),
		},
		{
			icon: 'scanner',
			title: 'Device',
			stateName: 'app.configuration.device',
			stateParameters: {},
			hidden: true || !user.settings.preferences.optInToTestingFeatures, // this was set as true || ... when I got here, so I left it that way
		},
		{
			icon: 'engine',
			title: 'Inventory Types',
			stateName: 'app.configuration.inventory-type',
			stateParameters: {},
			hidden: !checkSessionPermission('PART_CONFIGURATION_CAN_EDIT_PART_TYPES'),
		},
	]
}

export default function({ stateRouter }) {
	stateRouter.addState({
		name: 'app.configuration',
		route: 'configuration',
		defaultChild: () => {
			const childStates = getChildStates()
			const activeChildState = childStates.find(childState => !childState.hidden)
			return activeChildState?.stateName?.replace('app.configuration.', '') || false // return just the name of the child state
		},
		template: {
			template,
			components: {
				navTabBar: makeNavTabBar(stateRouter),
			},
		},
		resolve(_data, _parameters) {
			const childStates = getChildStates()
			return Promise.resolve({
				childStates,
				hasVisibleChildState: childStates.some(childState => !childState.hidden),
			})
		},
		activate({ domApi: ractive }) {
			// Look ma, no code!
		},
	})
}
