import pProps from 'p-props'
//import import makeReportSelectionModalComponent from 'components/report-selection'
//import import makeLoadItemModal from 'components/load-item-modal'
import makeItButton from '@isoftdata/button'
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'
import makeItModal from '@isoftdata/modal'
import makeItInput from '@isoftdata/input'
import { inventoryConditions, inventoryQueryById, inventoryTypesQuery, glCategoriesQuery, settingValuesQuery,
	modelCategoryPricingQuery, vendorsQuery,
	newInventoryMutation as newStandardInventoryMutation, newMiscInventoryMutation, newReplenishableInventoryMutation,
	userStatusListQuery, vehicleMakes, vehicleModelsByMake, storeLocations as storeLocationsQuery, inventoryOptionsQuery, inventoryTypeFieldsQuery } from 'graphql-queries'
import { sortArrayByObjectKey } from '@isoftdata/utility-array'
import makeAttachmentComponent from '@isoftdata/attachment'
import makePartBasic from 'components/part-basic'
import { klona } from 'klona'
import { v4 as uuid } from '@lukeed/uuid'
import { stringToBoolean } from '@isoftdata/utility-string'
import template from './part.html'
import debounced from 'utility/debounce'
import makeLoadItemModal from '@isoftdata/load-item-modal'
import ObjectMap from 'classes/ObjectMap'
import documentTypes from 'common/document-types'
import toTitleCase from 'to-title-case'
import { setObject, getObject } from '@isoftdata/utility-storage'
import { updateInventoryBasicMutation } from '../../mutations'
import { readAsDataURL } from '@isoftdata/promise-file-reader'

// #region Constants
const modelQuery = `query Models($manufacturerId: Int!, $inventoryTypeId: Int) {
	manufacturer(id: $manufacturerId) {
		  models(inventoryTypeId: $inventoryTypeId) {
		id
		name
		active
		defaultShippingDimensions {
		  height
		  length
		  measurementUnit
		  weight
		  weightUnit
		  width
		}
	   inventoryTypeId
	  }
	}
}`
// Please keep this alphabetical :)
// These should be the same values as we keep in memory, not necessarily the same as the API.
const defaultPart = Object.freeze({
	attachments: [],
	averageDemandPerDay: 'N/A',
	averageDemandPerMonth: 'N/A',
	bodyStyle: '',
	buyPackage: 1,
	category: {
		id: null,
		name: '',
	},
	condition: '',
	coreClass: '',
	coreRequired: false,
	coreRequiredToVendor: false,
	cost: 0,
	dateEntered: new Date(),
	dateModified: new Date(),
	dateViewed: new Date(),
	daysToReturn: 30,
	daysToReturnCore: 30,
	daysToReturnCoreToVendor: 30,
	daysToReturnToVendor: 30,
	defaultVendor: {
		id: null,
	},
	deplete: true,
	description: '',
	enteredBy: {
		id: null,
		name: null,
	},
	freezeUntil: null,
	glCategory: null,
	innodbInventoryid: null,
	inventoryId: null,
	inventoryOptions: [],
	inventoryType: null,
	inventoryTypeId: null,
	jobberPrice: 0,
	label: '',
	length: null,
	locations: [],
	manufacturerId: null,
	maxQuantity: 1,
	measurementUnit: '',
	minQuantity: 1,
	modelId: null,
	notes: '',
	oemNumber: '',
	parentManufacturerId: null,
	parentModelId: null,
	partNumber: '',
	popularityCode: '',
	productCode: '',
	public: false,
	quantity: 1,
	quantityAvailable: 1,
	quantityOnHold: 0,
	replenishable: false,
	retailCorePrice: 0,
	retailPrice: 0,
	returnable: true,
	returnableToVendor: true,
	safetyStockPercent: 0,
	saleClass: { code: 'NONE' },
	seasonal: false,
	sellPackage: 1,
	sellPriceClassId: null,
	serials: [],
	serialized: false,
	shippingDimensions: {
		height: null,
		length: null,
		measurementUnit: 'IN',
		weight: null,
		weightUnit: 'LB',
		width: null,
	},
	side: 'NA',
	singleQuantity: false,
	sku: null,
	status: 'A',
	stockCategory: 'MISC',
	stockingDays: 0,
	stockMethod: 'NONE',
	stockType: 'SPECIAL_ORDER',
	storeId: null,
	subInterchangeNumber: "",
	tagNumber: '',
	tagPrinted: false,
	taxable: true,
	typeField1: {
		data: '',
		label: '',
	},
	typeField2: {
		data: '',
		label: '',
	},
	typeField3: {
		data: '',
		label: '',
	},
	typeField4: {
		data: '',
		label: '',
	},
	userStatus: '',
	useVendorOrderMultiplier: false,
	vehicle: null,
	vehicleId: null,
	vehicleMake: '',
	vehicleModel: '',
	vehicleVin: '',
	vehicleYear: null,
	vendorLeadTime: 'N/A',
	vendorPopularityCode: '',
	vendorProductCode: '',
	wholesalePrice: 0,
})
// #endregion
// #region Function definitions
function loadModelsForMake(manufacturerId, inventoryTypeId, mediator) {
	return mediator.publish('graphqlFetchWithCache', {
		query: modelQuery,
		minutesToLive: 300,
		variables: { manufacturerId, inventoryTypeId },
		mutator: res => {
			let models = sortArrayByObjectKey({ array: res.manufacturer.models, key: 'name' })
			return (models && Array.isArray(models)) ? models : []
		},
	})
}

async function loadVehicleModelsForMake(make, mediator) {
	const res = await mediator.publish('graphqlFetch', vehicleModelsByMake, { make })
	const vehicleModels = sortArrayByObjectKey({ array: res.vehicleModels, key: 'name' }).map(row => {
		return row.name
	})
	return (vehicleModels && Array.isArray(vehicleModels)) ? vehicleModels : []
}

function isValidQuestionForPart(question, part) {
	return (question.manufacturerId === part.manufacturerId || question.manufacturerId === null)
		&& (question.modelId === part.modelId || question.modelId === null)
		&& (question.categoryName?.toLowerCase() === part.categoryName?.toLowerCase() || question.categoryName === null)
		&& (question.inventoryTypeId === part.inventoryTypeId || question.inventoryTypeId === null)
}

function transformInventoryOptionValue(dataType, value) {
	if (dataType === 'NUMBER') {
		return (value === null || value === '') ? null : Number(value)
	} else if (dataType === 'BOOLEAN') {
		return stringToBoolean(value)
	} else {
		return value
	}
}

function getOptionValueMap(part) {
	const inventoryOptionsForMap = part.inventoryOptions
		.filter(option => option.value) // don't include empty string or null values
		.map(option => ([{ optionId: option.id }, option.value ]))
	return new ObjectMap(
		[ 'serialId', 'optionId', 'serialUuid' ], // always all key parts, in case we serialize later
		part.serialized ?
			part.serials.reduce((acc, serial) => {
				return acc.concat(serial.inventoryOptions.map(option => ([{ serialId: serial.id, optionId: option.id, serialUuid: serial.uuid }, option.value ])))
			}, []).concat(inventoryOptionsForMap)
			: inventoryOptionsForMap,
	)
}

function flattenInventoryOptionForDisplay({ option, value }) {
	const { category, manufacturer, model, inventoryType, ...optionRest } = option
	return {
		...optionRest,
		value: transformInventoryOptionValue(option.dataType, value),
		categoryId: category?.id ?? null,
		categoryName: category?.name ?? null,
		manufacturerId: manufacturer?.id ?? null,
		modelId: model?.id ?? null,
		inventoryTypeId: inventoryType?.id ?? null,
	}
}

function flattenAttachment(item) {
	return { fileId: item.fileId, public: item.public, rank: item.rank, ...item.file }
}

function flattenAttachmentWithUuid(item) {
	return { ...flattenAttachment(item), uuid: uuid() }
}

function formatUsedOnDocument({ usedOnDocumentId, usedOnDocumentStoreId, usedOnDocumentType }) {
	const documentType = documentTypes[usedOnDocumentType]
	// Don't bother showing line ids, just show the document type / id
	let str = `${documentType.parentAbbreviation || documentType.abbreviation} `
	if (usedOnDocumentStoreId) {
		str += `${usedOnDocumentStoreId}-`
	}
	if (usedOnDocumentId) {
		str += usedOnDocumentId
	}
	return str
}

function formatSourceDocument({ enteredOnDocumentId, enteredOnDocumentStoreId, enteredOnDocumentType }) {
	return formatUsedOnDocument({ usedOnDocumentId: enteredOnDocumentId, usedOnDocumentStoreId: enteredOnDocumentStoreId, usedOnDocumentType: enteredOnDocumentType })
}

function getSaveEndpoint({ innodbInventoryid, replenishable, vehicleId }) {
	if (innodbInventoryid) {
		return updateInventoryBasicMutation
	}
	if (replenishable) {
		return newReplenishableInventoryMutation
	}
	if (vehicleId) {
		return newStandardInventoryMutation
	}
	return newMiscInventoryMutation
}

function clearCachedPart(mediator) {
	localStorage.removeItem('cachedPart')
	localStorage.removeItem('cachedOptionValues')
	mediator.publish('removeActivity', 'Unsaved Part')
}

async function loadPart(mediator, innodbInventoryid, loadCachedPart, settingValues) {
	const cachedPart = getObject(localStorage, 'cachedPart') || false
	if (loadCachedPart && cachedPart) {
		cachedPart.attachments = cachedPart.attachments.map(attachment => {
			if (attachment.cachePath) {
				return { ...attachment, File: decodeFileFromCache(attachment) }
			}
			return attachment
		})
		return cachedPart
	}
	if (innodbInventoryid) {
		const res = await mediator.publish('graphqlFetch', inventoryQueryById, { innodbInventoryid, serialFilter: { statuses: [ 'AVAILABLE', 'ON_HOLD', 'IN_TRANSIT' ] } })
		return res.inventory
	}
	return {
		...klona(defaultPart),
		daysToReturn: settingValues.inventory.defaultDaysToReturn,
		daysToReturnCore: settingValues.inventory.defaultDaysToReturnCore,
		daysToReturnCoreToVendor: settingValues.inventory.defaultDaysToReturnCoreToVendor,
		daysToReturnToVendor: settingValues.inventory.defaultDaysToReturnToVendor,
		returnable: settingValues.inventory.defaultReturnable,
		glCategory: { id: settingValues.inventory.defaultGlCategoryId },
		returnableToVendor: settingValues.inventory.defaultReturnableToVendor,
	}
}

function decodeFileFromCache({ cachePath, ...attachment }) {
	const [ meta, base64 ] = cachePath.split(',')
	const [ , mimeType ] = meta.match(/^data:(.*);base64$/) || []
	const byteCharacters = atob(base64)

	const byteNumbers = new Array(byteCharacters.length)
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i)
	}

	const byteArray = new Uint8Array(byteNumbers)

	return new File([ byteArray ], attachment.name, { type: mimeType })
}

// #endregion
export default function createState({ mediator, stateRouter, checkSessionPermission }) {
	const stateName = 'app.part'

	stateRouter.addState({
		name: stateName,
		route: 'part',
		querystringParameters: [ 'inventoryId', 'storeId', 'lastResetTime', 'loadCachedPart' ],
		defaultParameters: {
			inventoryId: null,
			storeId() {
				return JSON.parse(sessionStorage.getItem('user'))?.currentStore ?? null
			},
			tab: 'basic',
			lastResetTime: null, // only used to trigger a state reload
			loadCachedPart: false,
		},
		canLeaveState(ractive) {
			console.log('allow state change?', ractive)
			// ractive may be undefined before initial load
			return !ractive || !ractive.get('partChanged') || confirm('You have unsaved changes. Continue?')
		},
		template: {
			twoway: false,
			template,
			components: {
				itButton: makeItButton(),
				itModal: makeItModal(),
				itInput: makeItInput({ twoway: true }),
				partBasic: makePartBasic(mediator, {}),
				navTabBar: makeNavTabBar(stateRouter),
				loadPartModal: makeLoadItemModal({
					async doSearch(tagNumber, { lookupEndpoint }) {
						const results = await mediator.publish('graphqlFetch', lookupEndpoint, { filter: { tagNumber }, pagination: { pageNumber: 1, pageSize: 1 } })
						return results?.inventories?.items ?? []
					},
					lookupError(err, message) {
						console.error(err)
						alert(message)
					},
					chooseItem(item, { destinationStateName }) {
						stateRouter.go(destinationStateName, { inventoryId: item.id, storeId: item.storeId })
					},
				}),
				itAttachment: makeAttachmentComponent({ deferredSaving: true }),
			},
			computed: {
				partTitle() {
					const part = this.get('part')

					if (part) {
						const { tagNumber, inventoryTypeId, innodbInventoryid } = part
						const subtitle = [
							inventoryTypeId,
						//	manufacturer?.name,
						//	model?.name,
						].filter(val => val).join(' ')

						return {
							title: tagNumber || innodbInventoryid || 'New Part',
							subtitle,
						}
					}
					return {
						title: 'New Part',
						subtitle: '',
					}
				},
				partJson() {
					return JSON.stringify(this.get('part'), null, 3)
				},
				origPartJson() {
					return JSON.stringify(this.get('origPart'), null, 3)
				},
				inventoryTypeData() {
					const inventoryTypeList = this.get('inventoryTypeList')
					const inventoryTypeId = this.get('part.inventoryTypeId')
					return inventoryTypeList.find(type => type?.inventoryTypeId && type.inventoryTypeId.toString() === inventoryTypeId.toString()) ?? {}
				},
				inventoryOptionQueryFilter() {
					return {
						inventoryTypeId: parseInt(this.get('part.inventoryTypeId'), 10) || null,
						manufacturerId: parseInt(this.get('part.manufacturerId'), 10) || null,
						modelId: parseInt(this.get('part.modelId'), 10) || null,
						categoryName: this.get('part.category.name') || null,
					}
				},
				missingRequiredFields() {
					const part = this.get('part')
					const originalPart = this.get('origPart') || {}
					const settings = this.get('settingValues')
					const inventoryTypeData = this.get('inventoryTypeData')
					const isNew = !part.innodbInventoryid
					const missingFields = []

					if (settings.inventory.categoryRequired && !part.category?.id) {
						missingFields.push('Category')
					}
					if (settings.inventory.conditionRequired && !part.condition) {
						missingFields.push('Condition')
					}
					if (settings.inventory.costRequired && !part.cost) {
						missingFields.push('Cost')
					}
					if (settings.inventory.glCategoryRequired && !part.glCategory?.id) {
						missingFields.push('GL Category')
					}
					if (!part.inventoryTypeId) {
						missingFields.push('Inventory Type')
					}
					if (settings.inventory.jobberPriceRequired && !part.jobberPrice) {
						missingFields.push('Jobber Price')
					}
					if (settings.inventory.oemNumberRequired && (isNew || part.oemNumber !== originalPart.oemNumber) && !part.oemNumber) {
						missingFields.push('OEM Number')
					}
					if (settings.inventory.retailPriceRequired && !part.retailPrice) {
						missingFields.push('Retail Price')
					}
					if (settings.inventory.retailCorePriceRequired && !part.retailCorePrice) {
						missingFields.push('Retail Core Price')
					}
					if (settings.inventory.sideRequired && !part.side) {
						missingFields.push('Side')
					}
					if (settings.inventory.userStatusRequired && !part.userStatus) {
						missingFields.push('User Status')
					}
					if (settings.inventory.wholesalePriceRequired && !part.wholesalePrice) {
						missingFields.push('Wholesale Price')
					}
					if (inventoryTypeData?.requireSerialization && !part.serialized && !part.newSerial) {
						missingFields.push('Serial Number')
					}
					return missingFields
				},
				missingRequiredFieldsList() {
					return this.get('missingRequiredFields')?.join(', ') || ''
				},
				requiredFieldsComplete() {
					return this.get('missingRequiredFields')?.length === 0
				},
			},
			async checkModelCategoryPricing() {
				const ractive = this
				let part = ractive.get('part')
				if (part.modelId && part.category?.id) {
					const variables =	{
						modelId: parseInt(part.modelId, 10),
						categoryId: parseInt(part.category?.id, 10),
					}
					try {
						const res = await mediator.publish('graphqlFetch', modelCategoryPricingQuery, variables)
						if (res?.modelCategoryPricing) {
							const pricing = res.modelCategoryPricing.pricing
							ractive.set({
								'part.retailCorePrice': pricing.retailCore || part.retailCorePrice,
								'part.retailPrice': pricing.retail || part.retailPrice,
								'part.listPrice': pricing.list || part.listPrice,
								'part.wholesalePrice': pricing.wholesale || part.wholesalePrice,
								'part.jobberPrice': pricing.jobber || part.jobberPrice,
							})
						}
					} catch (err) {
						alert('something went wrong getting modelCategoryPricing')
					}
				}
			},
			convertDate(dateString) {
				if (typeof dateString === 'string') {
					dateString = new Date(dateString).toLocaleString()
				}
				return dateString
			},
			getOptionValuesForSave() {
				const ractive = this
				const optionValueMap = ractive.get('optionValueMap')

				const optionValuesToSave = optionValueMap.entries().reduce((acc, [{ serialId, optionId, serialUuid }, value ]) => {
					const serialKey = serialId || serialUuid // serialUuid is used for new serials
					// Have to include ALL optionValues for a given serial or else the absent ones will be cleared
					// Could be made more efficient if we exclude serials with no changes made to them. But if any are modified, we need to send the whole list.
					if (serialKey && optionId) { // Serial Q&A
						if (!acc[serialKey]) {
							acc[serialKey] = []
						}
						if (value) {
							acc[serialKey].push({
								inventoryOptionId: optionId,
								value: value?.toString?.() ?? '',
							})
						}
					} else if (optionId && value) { // Default / non-serial Q&A
						acc.optionValues.push({
							inventoryOptionId: optionId,
							value: value?.toString?.() ?? '',
						})
					}
					return acc
				}, { optionValues: [] })

				return optionValuesToSave
			},
			async savePart() {
				if (!checkSessionPermission('PARTS_CAN_SAVE_PARTS')) {
					return alert('You do not have permission to save parts.')
				}
				const ractive = this
				const part = ractive.get('part')

				if (part.quantity > 0 && !part.locations.length && !part.serialized) {
					return ractive.set('showNoLocationOnSaveModal', true)
				}

				mediator.publish('showMessage', { heading: 'Saving...', message: '', type: 'info', time: false })

				const originalPart = ractive.get('origPart')
				const varianceLocationName = this.get('settingValues.inventory.varianceLocationName') || 'Variance'
				const { optionValues, ...serialOptionValues } = ractive.getOptionValuesForSave()

				if (ractive.get('settingValues.location.enforceLocationHierarchy') && part.locations.some(location => !Number.isInteger(location.locationId) && location.name !== varianceLocationName)) {
					const virtualLocationName = part.locations.find(location => !Number.isInteger(location.locationId)).name
					return alert(`You are trying to use a location (${virtualLocationName}) that does not exist.\r\n\r\nMake sure are using locations that exist, or get permission from an administrator to use virtual locations.`)
				}

				console.log('savedPart', part)
				let variables = {}

				if (part.innodbInventoryid) {
					variables.innodbInventoryid = part.innodbInventoryid
				}

				const locationsForSave = part.serialized ? undefined : part.locations.reduce(({ create, update }, { id: inventoryLocationId, locationId, name: locationName, rank, quantity, permanent, deleted }) => {
					if (!inventoryLocationId) {
						create.push({
							locationId: Number.isInteger(locationId) ? locationId : null,
							locationName,
							permanent,
							quantity,
							rank,
						})
					} else {
						update.push({
							id: inventoryLocationId,
							permanent: deleted ? false : permanent,
							quantity: deleted ? 0 : quantity,
							rank,
						})
					}
					// quantity 0, permanent false -> remove
					return { create, update }
				}, { create: [], update: [] })

				const attachmentsForSave = ractive.get('part.attachments').reduce(({ create, remove, update }, attachment) => {
					if (attachment.action === 'CREATE') {
						create.push({
							file: attachment.File, // TODO, make work
							public: attachment.public,
							rank: attachment.rank,
						})
					} else if (attachment.action === 'UPDATE') {
						update.push({
							id: attachment.fileId,
							public: attachment.public,
							rank: attachment.rank,
						})
					} else if (attachment.action === 'DELETE') {
						remove.push(attachment.fileId)
					}

					return { create, remove, update }
				}, { create: [], remove: [], update: [] })

				const serialsForSave = part.serials.reduce((serials, serial) => {
					let newSerial = {
						number: serial.number,
						location: serial.location?.name, // Does the API handle this being absent?
						optionValues: serialOptionValues[serial.id || serial.uuid] || undefined,
					}
					let action = 'CREATE'

					if (serial.deleted) {
						action = 'DELETE'
					} else if (serial.id) {
						action = 'UPDATE'
					}

					if (part.innodbInventoryid) { // Only need id and action on existing parts
						serials.push({
							...newSerial,
							id: serial.id,
							action,
						})
					} else if (!serial.deleted) { // on new parts, don't need to send deleted serials
						serials.push(newSerial)
					}
					return serials
				}, [])

				variables.part = {
					attachments: part.innodbInventoryid ? { update: attachmentsForSave.update, remove: attachmentsForSave.remove } : undefined, // Create is handled later
					bodyStyle: (!part.vehicleId || part.innodbInventoryid) ? part.bodyStyle : undefined, // Standard inventory will inherit from vehicle
					buyPackage: part.replenishable ? part.buyPackage : undefined, // replenishable only
					categoryId: parseInt(part.category?.id, 10),
					condition: part.condition,
					coreClass: part.replenishable ? part.coreClass : undefined,
					coreCost: part.coreCost,
					coreRequired: stringToBoolean(part.coreRequired),
					coreRequiredToVendor: stringToBoolean(part.coreRequiredToVendor),
					cost: part.cost,
					daysToReturn: parseInt(part.daysToReturn, 10),
					daysToReturnCoreToVendor: parseInt(part.daysToReturnCoreToVendor, 10),
					daysToReturnToVendor: parseInt(part.daysToReturnToVendor, 10),
					daysToReturnCore: parseInt(part.daysToReturnCore, 10),
					deplete: stringToBoolean(part.deplete),
					description: part.description,
					distributorCorePrice: part.distributorCorePrice,
					distributorPrice: part.distributorPrice,
					glCategoryId: part.glCategory?.id || null,
					interchangeNumber: part.interchangeNumber,
					inventoryTypeId: part.innodbInventoryid ? undefined : parseInt(part.inventoryTypeId, 10), // only for creation
					jobberCorePrice: part.jobberCorePrice,
					jobberPrice: part.jobberPrice,
					locations: part.innodbInventoryid ? locationsForSave : locationsForSave?.create, // new = only create
					manufacturerId: parseInt(part.manufacturerId, 10) || null,
					maxQuantity: part.replenishable ? parseFloat(part.maxQuantity) : undefined, // replenishable only
					minQuantity: part.replenishable ? parseFloat(part.minQuantity) : undefined, // replenishable only
					modelId: parseInt(part.modelId, 10) || null,
					notes: part.notes,
					oemNumber: part.oemNumber,
					optionValues,
					parentManufacturerId: parseInt(part.parentManufacturerId, 10) || null,
					parentModelId: parseInt(part.parentModelId, 10) || null,
					partNumber: part.replenishable ? part.partNumber : undefined,
					popularityCode: part.popularityCode,
					printTag: stringToBoolean(part.tagPrinted),
					public: stringToBoolean(part.public),
					purchaseFactor: part.replenishable ? stringToBoolean(part.useVendorOrderMultiplier) : undefined,
					quantity: checkSessionPermission('PARTS_CAN_EDIT_QUANTITY') ? parseFloat(part.quantity) : undefined,
					replenishable: part.innodbInventoryid ? stringToBoolean(part.replenishable) : undefined, // only for update
					retailCorePrice: part.retailCorePrice,
					retailPrice: part.retailPrice,
					returnable: stringToBoolean(part.returnable),
					returnableToVendor: stringToBoolean(part.returnableToVendor),
					saleClassCode: part.saleClass.code,
					sellPackage: part.sellPackage,
					sellPriceClassId: parseInt(part.sellPriceClassId, 10) || null,
					serialized: part.serialized || originalPart.serialized, // do not allow un-serializing a part
					serials: part.serialized ? serialsForSave : [],
					shippingDimensions: part.shippingDimensions,
					side: part.side || 'NA', // have to pass a side option to the API
					singleQuantity: stringToBoolean(part.singleQuantity), // Not on Update
					status: part.status || 'A',
					storeId: part.innodbInventoryid ? undefined : ractive.get('storeId'), // Only for creation
					subInterchangeNumber: part.subInterchangeNumber,
					tagNumber: part.tagNumber || undefined,
					taxable: stringToBoolean(part.taxable),
					typeData1: part.typeField1?.data,
					typeData2: part.typeField2?.data,
					typeData3: part.typeField3?.data,
					typeData4: part.typeField4?.data,
					userStatus: part.userStatus || null,
					vehicleId: parseInt(part.vehicleId, 10) || undefined, // only for Standard Inventory & Updates
					vehicleYear: (!part.vehicleId || part.innodbInventoryid) ? ((part.vehicleYear && parseInt(part.vehicleYear, 10)) || null) : undefined, // Standard inventory will inherit from vehicle
					vehicleModel: (!part.vehicleId || part.innodbInventoryid) ? part.vehicleModel : undefined, // Standard inventory will inherit from vehicle
					vehicleMake: (!part.vehicleId || part.innodbInventoryid) ? part.vehicleMake : undefined, // Standard inventory will inherit from vehicle
					vehicleVin: (!part.vehicleId || part.innodbInventoryid) ? part.vehicleVin : undefined, // Standard inventory will inherit from vehicle
					vendorPopularityCode: part.replenishable ? part.vendorPopularityCode : undefined, // only replenishable
					vendorProductCode: (part.replenishable || part.innodbInventoryid) ? part.productCode : undefined, // only replenishable and Update
					wholesaleCorePrice: part.wholesaleCorePrice,
					wholesalePrice: part.wholesalePrice,
				}
				if (part.category?.id === -1) {
					delete variables.part.categoryId
				}
				try {
					const query = getSaveEndpoint(part)
					const res = await mediator.publish('graphqlFetch', query, variables)
					const savedPart = res.part
					if (!savedPart) {
						throw new Error('No part returned from API. Maybe there was an error?')
					}

					savedPart.modelId = savedPart.model ? savedPart.model?.id : null
					savedPart.manufacturerId = savedPart.manufacturer ? savedPart.manufacturer?.id : null
					savedPart.parentModelId = savedPart.parentModel ? savedPart.parentModel?.id : null
					savedPart.parentManufacturerId = savedPart.parentManufacturer ? savedPart.parentManufacturer?.id : null
					savedPart.inventoryOptions = savedPart.inventoryOptions
						.map(flattenInventoryOptionForDisplay)
						.filter(option => isValidQuestionForPart(option, { ...savedPart, categoryName: savedPart.category?.name }))
						.sort((a, b) => a.rank - b.rank)
					savedPart.attachments = savedPart.attachments.map(flattenAttachmentWithUuid) ?? []
					savedPart.locations = savedPart.locations.map(({ quantity, holdQuantity, ...location }) => ({ ...location, quantity: parseFloat(quantity), holdQuantity: parseFloat(holdQuantity) }))
					savedPart.quantity = parseFloat(savedPart.quantity)
					savedPart.quantityAvailable = parseFloat(savedPart.quantityAvailable)
					savedPart.quantityOnHold = parseFloat(savedPart.quantityOnHold)
					if (!savedPart.serialized) {
						// This is not used for saving anymore, but it's used for the UI
						savedPart.newSerial = ""
					} else {
						savedPart.serials = savedPart.serials.map(serial => {
							serial.inventoryOptions = serial.inventoryOptions.map(flattenInventoryOptionForDisplay)
							serial.uuid = uuid()
							serial.usedOn = formatUsedOnDocument(serial)
							serial.source = formatSourceDocument(serial)
							serial.displayStatus = toTitleCase(serial.status)
							return serial
						}).sort((a, b) => a.status.localeCompare(b.status) || a.number.localeCompare(b.number)) ?? []
					}

					const { data: { createItemAttachments: fileRes } } = await mediator.publish(
						'uploadFileAttachments',
						savedPart.sku,
						attachmentsForSave.create.map(attachment => ({
							...attachment,
							relationId: savedPart.innodbInventoryid,
							relation: 'inventory',
						})) ?? [])

					savedPart.attachments = savedPart.attachments.concat(fileRes.map(flattenAttachmentWithUuid) ?? []) ?? []

					ractive.get('partValueChangeObserver')?.silence()
					ractive.set({
						origPart: klona(savedPart),
						part: savedPart,
						optionValueMap: getOptionValueMap(savedPart),
						partChanged: false,
					})

					// Clear the cached part if we're saving it
					const cachedPart = getObject(localStorage, 'cachedPart') || false
					if (cachedPart && (ractive.get('loadCachedPart') || cachedPart.innodbInventoryid === savedPart.innodbInventoryid)) {
						clearCachedPart(mediator)
					}

					mediator.publish('showMessage', { type: 'success', heading: 'Saved!', message: 'Part saved successfully.', time: 10000 })
					// Reloading the whole state seems excessive, but it'll only happen if we need to set one of these parameters
					stateRouter.go(null, { loadCachedPart: false, inventoryId: savedPart.innodbInventoryid }, { inherit: true, replace: true })
				} catch (err) {
					let message = err.message
					if (Array.isArray(err)) {
						message = err.map(e => e.message).join('\n')
					}
					return mediator.publish('showMessage', { type: 'danger', heading: 'Failed to save part', message, time: false })
				} finally {
					ractive.get('partValueChangeObserver')?.resume()
				}
			},
			async setTab(tab) {
				const ractive = this
				const partValueChangeObserver = ractive.get('partValueChangeObserver')
				partValueChangeObserver?.silence()
				// Some things might change state of stuff during a tab transition, but we don't want to trigger change detection for that
				await this.set('tab', tab)
				stateRouter.go(null, { tab }, { inherit: true }) // this will not reload the state
				partValueChangeObserver?.resume()
				ractive.set('partValueChangeObserver', partValueChangeObserver)
			},
		},
		async resolve(data, { inventoryId, storeId, loadCachedPart, tab }) {
			if (!checkSessionPermission('PARTS_CAN_VIEW_PARTS')) {
				alert('You do not have permission to view parts.')
				throw {
					redirectTo: {
						name: 'app',
					},
				}
			}

			storeId = parseInt(storeId, 10)
			loadCachedPart = stringToBoolean(loadCachedPart)
			const hasCachedPart = !!getObject(localStorage, 'cachedPart') || false
			if (loadCachedPart && !hasCachedPart) {
				clearCachedPart(mediator) // if it's not in localStorage, remove it from the sidebar
				throw {
					redirectTo: {
						name: null,
						params: {
							inventoryId,
							storeId,
							loadCachedPart: false,
						},
					},
				}
			}
			const innodbInventoryid = parseInt(inventoryId, 10) || null
			// We have to get the settingValues first, so we can use them to init a new part
			const settingValues = await mediator.publish('graphqlFetchWithCache', { query: settingValuesQuery, mutator: res => res.settingValues })

			const stageOneRes = await pProps({
				inventoryConditions: mediator.publish('graphqlFetchWithCache', { query: inventoryConditions, mutator: res => res.inventoryConditions }),
				userPartStatusList: mediator.publish('graphqlFetchWithCache', { query: userStatusListQuery, mutator: res => res.userPartStatusList }),
				part: loadPart(mediator, innodbInventoryid, loadCachedPart, settingValues),
				inventoryTypeList: mediator.publish('graphqlFetchWithCache', { query: inventoryTypesQuery, variables: { active: true }, minutesToLive: 300, mutator: res => {
					let inventoryTypeList = sortArrayByObjectKey({ array: res.inventoryTypeList, key: 'name' })

					inventoryTypeList = inventoryTypeList.map(obj => {
						obj.partManufacturers = sortArrayByObjectKey({ array: obj.partManufacturers, key: 'name' })
						return obj
					})
					return inventoryTypeList
				} }),
				glCategories: mediator.publish('graphqlFetchWithCache', { query: glCategoriesQuery, mutator: res => res.glCategories }),
				vehicleMakes: mediator.publish('graphqlFetchWithCache', { query: vehicleMakes, mutator: res => res.vehicleMakes }),
				vendorList: mediator.publish('graphqlFetchWithCache', { query: vendorsQuery, variables: { pagination: { pageSize: 0 } }, mutator: res => res.vendors.items }),
				inventoryId: innodbInventoryid,
				innodbInventoryid,
				trueModels: [],
				assyModels: [],
				trueModelsLoading: false,
				assyModelsLoading: false,
				sellPriceClasses: mediator.publish('graphqlFetchWithCache', { query: 'query { sellPriceClasses { id name parent { id name } } }', mutator: res => res.sellPriceClasses }),
				saleClasses: mediator.publish('graphqlFetchWithCache', { query: 'query SaleClasses { saleClasses { code name }}', mutator: res => res.saleClasses }),
				showPartHistoryModal: false,
				storeId,
				supportsWebShare: !!navigator.share,
				tagNumberModalShown: false,
				tab,
			})
			if (stageOneRes.part.innodbInventoryid && !loadCachedPart) { // cached/new part will already have this done to it
				stageOneRes.part.modelId = stageOneRes.part.model ? stageOneRes.part.model.id : null
				stageOneRes.part.manufacturerId = stageOneRes.part.manufacturer ? stageOneRes.part.manufacturer.id : null
				stageOneRes.part.parentModelId = stageOneRes.part.parentModel ? stageOneRes.part.parentModel.id : null
				stageOneRes.part.parentManufacturerId = stageOneRes.part.parentManufacturer ? stageOneRes.part.parentManufacturer.id : null
				stageOneRes.part.locations = stageOneRes.part.locations ?
				// uuid is so we have a unique key for the stepper buttons, even on unsaved locations
					stageOneRes.part.locations.map(({ location, ...inventoryLocation }) => ({
						...location,
						...inventoryLocation,
						quantity: parseFloat(inventoryLocation.quantity),
						holdQuantity: parseFloat(inventoryLocation.holdQuantity),
						uuid: uuid(),
						deleted: false,
					})).sort((a, b) => a.rank - b.rank) : []
				// On a serialized part, this will be the default serial Q&A. On a non-serialized part, this is the only Q&A.
				stageOneRes.part.inventoryOptions = stageOneRes.part.inventoryOptions
					.map(flattenInventoryOptionForDisplay)
					.filter(option => isValidQuestionForPart(option, { ...stageOneRes.part, categoryName: stageOneRes.part.category?.name }))
					.sort((a, b) => a.rank - b.rank)
				if (!stageOneRes.part.serialized) {
				// This isn't used for saving anymore, but it's used for the UI
					stageOneRes.part.newSerial = ''
				} else {
					stageOneRes.part.serials = stageOneRes.part.serials.map(serial => {
						serial.inventoryOptions = serial.inventoryOptions.map(flattenInventoryOptionForDisplay)
						serial.uuid = uuid()
						serial.usedOn = formatUsedOnDocument(serial)
						serial.source = formatSourceDocument(serial)
						serial.displayStatus = toTitleCase(serial.status)
						return serial
					}).sort((a, b) => a.status.localeCompare(b.status) || a.number.localeCompare(b.number)) ?? []
				}
				stageOneRes.part.singleQuantity = stageOneRes.part.singleQuantity ?? false // temp fix for missing API field
				stageOneRes.part.tagPrinted = !stageOneRes.part.printTag
				// hopefully fix trailing 0 issue
				stageOneRes.part.sellPackage = parseFloat(stageOneRes.part.sellPackage)
				stageOneRes.part.buyPackage = parseFloat(stageOneRes.part.buyPackage)
				stageOneRes.part.minQuantity = parseFloat(stageOneRes.part.minQuantity)
				stageOneRes.part.maxQuantity = parseFloat(stageOneRes.part.maxQuantity)
				stageOneRes.part.quantity = parseFloat(stageOneRes.part.quantity)
				stageOneRes.part.quantityOnHold = parseFloat(stageOneRes.part.quantityOnHold)
				stageOneRes.part.quantityAvailable = parseFloat(stageOneRes.part.quantityAvailable)
				stageOneRes.part.defaultVendor = stageOneRes.part.defaultVendor || { id: null }

				stageOneRes.part.attachments = stageOneRes.part.attachments?.map(flattenAttachment) ?? []
			}

			const stageTwoLoads = {
				// locations at the store the part is at. Used when adding a new location to the part
				partStoreLocations: mediator.publish('graphqlFetchWithCache', { query: storeLocationsQuery, minutesToLive: 300, variables: { storeId: stageOneRes.part.storeId ?? storeId } }),
			}

			if (stageOneRes.part.manufacturerId) {
				stageTwoLoads.trueModels = mediator.publish('graphqlFetchWithCache', {
					query: modelQuery, minutesToLive: 300, variables: { manufacturerId: stageOneRes.part.manufacturerId, inventoryTypeId: stageOneRes.part.inventoryTypeId },
					mutator: res => {
						let models = sortArrayByObjectKey({ array: res.manufacturer.models, key: 'name' })
						return (models && Array.isArray(models)) ? models : []
					},
				})
			}

			if (stageOneRes.part.parentManufacturerId) {
				stageTwoLoads.assyModels = mediator.publish('graphqlFetchWithCache', {
					query: modelQuery, minutesToLive: 300, variables: { manufacturerId: stageOneRes.part.parentManufacturerId, inventoryTypeId: stageOneRes.part.inventoryType.setId },
					mutator: res => {
						let models = sortArrayByObjectKey({ array: res.manufacturer.models, key: 'name' })
						return (models && Array.isArray(models)) ? models : []
					},
				})
			}
			// Used to track which options are/were shown so defaults are only applied to new options
			const existingOptionIdsSet = new Set(stageOneRes.part?.inventoryOptions?.map(option => option.id))

			const { partStoreLocations, ...stageTwoRes } = await pProps(stageTwoLoads)

			// It's technically possible to have a cached part and not a cached optionValueMap
			const cachedOptionValueMap = getObject(localStorage, 'cachedOptionValues')
			const optionValueMap = (loadCachedPart && cachedOptionValueMap) ?
				new ObjectMap(cachedOptionValueMap.keyParts, cachedOptionValueMap.entries)
				: getOptionValueMap(stageOneRes.part)

			return {
				...stageOneRes,
				...stageTwoRes,
				settingValues,
				partStoreLocations: partStoreLocations.locations,
				origPart: klona(stageOneRes.part),
				optionValueMap,
				existingOptionIdsSet,
				nonPartValueChanged: false,
				loadCachedPart,
				partChanged: loadCachedPart,
				sellPriceClasses: stageOneRes.sellPriceClasses.sort((a, b) => {
					const aDisplayName = a.parent?.name ? `${a.parent.name} - ${a.name}` : a.name
					const bDisplayName = b.parent?.name ? `${b.parent.name} - ${b.name}` : b.name
					return aDisplayName.localeCompare(bDisplayName)
				}),
			}
		},
		activate(context) {
			const { domApi: ractive, parameters } = context
			window.scrollTo(0, 0)

			console.log('part state activated', ractive.get())

			if (ractive.get('part.inventoryId') && !stringToBoolean(parameters.loadCachedPart)) {
				mediator.publish('activity', {
					stateName,
					stateParameters: parameters,
					stateCategory: 'PART',
					action: 'VIEW',
					displayTitle: `#${ractive.get('part.tagNumber') || ractive.get('part.inventoryId')} (${ractive.get('inventoryTypeData.inventoryTypeId')})`,
					stateParameterKey: 'inventoryId',
					icon: 'fa-engine',
				})
			}

			ractive.on('showLoadPartModal', (context, searchString = '') => {
				const query = `#graphql
				query InventoryLookup($filter: InventoryFilter) {
					inventories(filter: $filter) {
					  items {
						id
						store {
							code
							name
						}
						tagNumber
						sku
						inventoryType{
							id
							name
						}
					  }
					}
				  }`
				ractive.findComponent('loadPartModal').fire('show', {}, {
					itemTitle: 'Parts by Tag Number',
					lookupEndpoint: query,
					itemIdProp: 'id',
					itemDisplayProp: 'tagNumber',
					modalSizeClass: 'modal-md',
					searchString,
				})
				if (searchString) {
					ractive.findComponent('loadPartModal').fire('lookup', {}, searchString)
				}
			})

			ractive.on('add-location-and-save', (_context, locationName) => {
				const quantity = parseFloat(ractive.get('part.quantity'))
				const partStoreLocations = ractive.get('partStoreLocations')
				const existingLocation = partStoreLocations.find(location => location.name.toLowerCase() === locationName.toLowerCase())
				ractive.push('part.locations', {
					description: existingLocation?.description ?? '',
					allowInventory: true,
					id: null,
					locationId: existingLocation?.locationId ?? null,
					name: locationName,
					rank: 1,
					quantity,
					holdQuantity: 0,
					permanent: false,
					uuid: uuid(),
					deleted: false,
				})
				ractive.set('showNoLocationOnSaveModal', false)
				ractive.savePart()
			})

			// manufacturerId, modelId, categoryName, inventoryTypeId
			const inventoryOptionFilterObserver = ractive.observe('inventoryOptionQueryFilter', debounced(100, async filter => {
				console.log('refreshing valid inventory options')
				const optionValueMap = ractive.get('optionValueMap')
				ractive.set({ inventoryOptionsLoading: true })

				let loads = {
					inventoryOptionsRes: mediator.publish('graphqlFetchPath', inventoryOptionsQuery, filter, 'inventoryOptions'),
				}
				if (filter.inventoryTypeId !== ractive.get('part.inventoryType.id') && filter.inventoryTypeId) {
					loads.typeFields = mediator.publish('graphqlFetchPath', inventoryTypeFieldsQuery, { inventoryTypeId: filter.inventoryTypeId }, 'inventoryType')
				}
				const { inventoryOptionsRes, typeFields } = await pProps(loads)

				let valuesToSet = {
					'inventoryOptionsLoading': false,
				}

				// If we change inventoryType, we need to set the category to one with a matching name for the new type as the id will not match
				const matchingCategory = ractive.get('inventoryTypeData.categories')?.find(category => category.name?.toLowerCase() === filter.categoryName?.toLowerCase())
				if (matchingCategory) {
					valuesToSet['part.category'] = matchingCategory
				} else {
					valuesToSet['part.category'] = null
				}

				// Set the type fields (with no value) and other inventory type fields
				if (filter.inventoryTypeId !== ractive.get('part.inventoryType.id')) {
					const existingTypeFields = [
						ractive.get('part.typeField1'),
						ractive.get('part.typeField2'),
						ractive.get('part.typeField3'),
						ractive.get('part.typeField4'),
					].reduce((acc, field) => {
						if (field?.label) {
							acc[field.label] = field.data
						}
						return acc
					}, {})

					valuesToSet = Object.entries(typeFields ?? {}).reduce((acc, [ key, value ]) => {
						if (key.startsWith('typeLabel')) {
							// value is the type field label
							acc[`part.typeField${key.slice(-1)}`] = { label: value ?? '', data: existingTypeFields[value] ?? '' }
						} else {
							// id, setId, histories
							acc[`part.inventoryType.${key}`] = value
						}
						return acc
					}, valuesToSet)
				}

				const newInventoryOptions = inventoryOptionsRes.map(option => flattenInventoryOptionForDisplay({ option })).sort((a, b) => a.rank - b.rank)
				const newInventoryOptionIdsSet = new Set(newInventoryOptions.map(option => option.id))
				const oldOptionIdsToNamesMap = ractive.get('part.inventoryOptions').reduce((acc, option) => {
					acc[option.id] = option.name
					return acc
				}, {})
				const { newOptionNamesToIdsMap, newDefaultOptionValueMap } = newInventoryOptions.reduce((acc, option) => {
					acc.newOptionNamesToIdsMap[option.name] = option.id
					if (option.defaultChoice) {
						acc.newDefaultOptionValueMap[option.id] = option.defaultChoice
					}
					return acc
				}, { newOptionNamesToIdsMap: {}, newDefaultOptionValueMap: {} })

				// Put old empty values in the big ol map so we can revert to them if the user changes their mind
				for (const option of ractive.get('part.inventoryOptions')) {
					for (const serial of ractive.get('part.serials')) {
						if (!optionValueMap.has({ optionId: option.id, serialId: serial.id, serialUuid: serial.uuid })) {
							optionValueMap.set({ optionId: option.id, serialId: serial.id, serialUuid: serial.uuid }, '')
						}
					}
					if (!optionValueMap.has({ optionId: option.id })) {
						optionValueMap.set({ optionId: option.id }, '')
					}
				}
				// Put new empty values in the big ol map so defaults get computed in the next step
				for (const option of newInventoryOptions) {
					for (const serial of ractive.get('part.serials')) {
						if (!optionValueMap.has({ optionId: option.id, serialId: serial.id, serialUuid: serial.uuid })) {
							// Real values won't ever be false. This will let us see this is only there to apply defaults, and is not a real empty value
							optionValueMap.set({ optionId: option.id, serialId: serial.id, serialUuid: serial.uuid }, false)
						}
					}
					if (!optionValueMap.has({ optionId: option.id })) {
						optionValueMap.set({ optionId: option.id }, false)
					}
				}
				for (const [{ optionId, serialId, serialUuid, valueWasDefault = false }, value ] of optionValueMap.entries()) {
					const newIdWithMatchingName = newOptionNamesToIdsMap[oldOptionIdsToNamesMap[optionId]]
					// Is there a matching option by id in the new list? And do we have an existing value? Or, have we already changed this value and don't need to again?
					if ((newInventoryOptionIdsSet.has(optionId) && typeof optionValueMap.get({ optionId: newIdWithMatchingName, serialId, serialUuid }) === 'string') || optionValueMap.get({ optionId, serialId, serialUuid }) !== value) { // Yes? Carry on.
						continue
					} else if (value && newIdWithMatchingName && !valueWasDefault) { // If this has a value, is there a matching option by name? This should not carry forwartd values that were applied as a default so new defaults can be applied
						// If so, move the optionId in the map to the new id
						optionValueMap.set({ optionId: newIdWithMatchingName, serialId, serialUuid }, value)
						// Delete the old one, we can just flip it back if the user changes their mind
						optionValueMap.delete({ optionId, serialId, serialUuid })
					} else if (typeof value !== 'string' && optionValueMap.get({ optionId }) && serialUuid /** <- TODO: Make sure changing this to serialUuid didn't break anything */) { // Check for existing default value and apply/keep if found
						optionValueMap.set({ optionId, serialId, serialUuid, valueWasDefault: true }, optionValueMap.get({ optionId }))
					} else if (typeof optionValueMap.get({ optionId, serialId, serialUuid }) !== 'string' && newDefaultOptionValueMap[optionId]) { // Check for new default value and apply if an existing one wasn't found
						optionValueMap.set({ optionId, serialId, serialUuid, valueWasDefault: true }, newDefaultOptionValueMap[optionId])
					} else if (value === false) {
						// This is an empty value that was only there to apply defaults or a default that no longer applies, remove it.
						optionValueMap.delete({ optionId, serialId, serialUuid })
					}
					// if not, then we just don't have a value.
				}

				// I'm really not sure why, but Ractive will error if I set this array with everything else or without shuffle on
				// So this forces it to re-render the inventory options harder and it works
				ractive.set('part.inventoryOptions', newInventoryOptions, { shuffle: true })
				ractive.set({ ...valuesToSet, optionValueMap })
			}), { init: false })

			// #region Basic Info Observers
			const parentManufacturerIdObserer = ractive.observe('part.parentManufacturerId', async manufacturerId => {
				if (manufacturerId) {
					const inventorySetTypeId = ractive.get('inventoryTypeData.typeSetId')
					ractive.set({ assyModelsLoading: true })
					const assyModels = await loadModelsForMake(parseInt(manufacturerId, 10), parseInt(inventorySetTypeId, 10), mediator)
					ractive.set({ assyModelsLoading: false, assyModels })
				} else {
					ractive.set({ assyModels: [] })
				}
			}, { init: false })

			const manufacturerIdObserver = ractive.observe('part.manufacturerId', async manufacturerId => {
				if (manufacturerId) {
					const inventoryTypeId = ractive.get('part.inventoryTypeId')
					ractive.set({ trueModelsLoading: true })
					const trueModels = await loadModelsForMake(parseInt(manufacturerId, 10), parseInt(inventoryTypeId, 10), mediator)
					ractive.set({ trueModelsLoading: false, trueModels })
				} else {
					ractive.set({ trueModels: [] })
				}
			}, { init: false })

			const modelIdObserver = ractive.observe('part.modelId', modelId => {
				if (modelId) {
					const models = ractive.get('trueModels')
					const model = models.find(model => model.id == modelId)

					let shippingDimensions = ractive.get('part.shippingDimensions')
					if (model && model.defaultShippingDimensions) {
						shippingDimensions.weightUnit = model.defaultShippingDimensions.weightUnit || shippingDimensions.weightUnit
						shippingDimensions.measurementUnit = model.defaultShippingDimensions.measurementUnit || shippingDimensions.measurementUnit
						shippingDimensions.weight = model.defaultShippingDimensions.weight || shippingDimensions.weight
						shippingDimensions.length = model.defaultShippingDimensions.length || shippingDimensions.length
						shippingDimensions.width = model.defaultShippingDimensions.width || shippingDimensions.width
						shippingDimensions.height = model.defaultShippingDimensions.height || shippingDimensions.height

						ractive.set('part.shippingDimensions', shippingDimensions)
					}
					ractive.checkModelCategoryPricing()
				}
			}, { init: false })

			const categoryIdObserver = ractive.observe('part.category.id', categoryID => {
				if (categoryID) {
					ractive.checkModelCategoryPricing()
				}
			}, { init: false })

			const vehicleMakeObserver = ractive.observe('part.vehicleMake', async vehicleMake => {
				vehicleMake = vehicleMake.toUpperCase()
				ractive.set('part.vehicleMake', vehicleMake)
				if (vehicleMake) {
					const vehicleModels = await loadVehicleModelsForMake(vehicleMake, mediator)
					console.log('vehicle models??: ', vehicleModels)
					ractive.set({ vehicleModels })
				}
			})
			const vehicleModelObserver = ractive.observe('part.vehicleModel', vehicleModel => {
				vehicleModel = vehicleModel.toUpperCase()
				ractive.set('part.vehicleMake', vehicleModel)
			})
			// #endregion
			// #region Caching Observers
			const partValueChangeObserver = ractive.observe('optionValueMap part.*', async(newVal, oldVal, keypath) => {
				if (keypath === 'part.appraisal') {
					return // we don't care about appraisal updates since we don't save it
				}
				console.log('part changed', keypath, newVal)
				if (!ractive.get('partChanged')) {
					// Remove old "Unsaved Part"s before we add the new one since we only store one unsaved part in memory
					clearCachedPart(mediator)
					mediator.publish('activity', {
						stateName,
						stateParameters: { ...parameters, loadCachedPart: true },
						stateCategory: 'PART',
						action: 'VIEW',
						displayTitle: 'Unsaved Part',
						stateParameterKey: 'inventoryId',
					})
					ractive.set('partChanged', true)
				}
				const partToCache = klona(ractive.get('part'))
				if (keypath.startsWith('optionValueMap')) {
					// Unfortunately if we want to cache the part on every change, we have to loop through all option values every time one is changed
					const optionValueMap = ractive.get('optionValueMap')
					setObject(localStorage, 'cachedOptionValues', {
						keyParts: optionValueMap.keyParts,
						entries: optionValueMap.entries(),
					})
				} else if (keypath.startsWith('part.attachments')) {
					partToCache.attachments = await Promise.all(partToCache.attachments.map(async attachment => {
						if (attachment.path.startsWith('blob:')) {
							attachment.path = '' // so the attachment component rebuilds this on reload
							attachment.cachePath = await readAsDataURL(attachment.File) // so we can restore the image on reload
						}
						return attachment
					}))
				}
				setObject(localStorage, 'cachedPart', partToCache)
			}, { init: false })
			ractive.set('partValueChangeObserver', partValueChangeObserver)
			// #endregion

			context.on('destroy', () => {
				inventoryOptionFilterObserver.cancel()
				partValueChangeObserver.cancel()
				ractive.set('partValueChangeObserver', false)
				parentManufacturerIdObserer.cancel()
				manufacturerIdObserver.cancel()
				modelIdObserver.cancel()
				categoryIdObserver.cancel()
				vehicleMakeObserver.cancel()
				vehicleModelObserver.cancel()
				// cancelCacheProvider()
				if (ractive.get('partChanged')) {
					mediator.publish('showMessage', { heading: 'Changes saved for later...', message: 'Click "Unsaved Part" in "Recent Activity" to resume your work.', type: 'info', time: 10000 })
				}
			})
		},
	})
}
